import { Loader } from '@googlemaps/js-api-loader';

export default class GoogleMapsService {
  constructor() {
    const googleMaps = new Loader({
      apiKey: process.env.VUE_APP_GOOGLE_MAPS_KEY,
      version: 'weekly',
      libraries: ['places'],
    });

    googleMaps.load().then((google) => {
      this.places = new google.maps.places.AutocompleteService();
      this.sessionToken = new google.maps.places.AutocompleteSessionToken();
      this.geocoder = new google.maps.Geocoder();
    });
  }

  async getPlaces(searchValue) {
    const response = await this.places.getPlacePredictions({
      input: searchValue,
      sessionToken: this.sessionToken,
      componentRestrictions: { country: ['us', 'ca'] },
      fields: ['formatted_address'],
      types: ['address'],
    });

    return response.predictions || {};
  }

  async getGeoCodedAddress(placeId) {
    let resultResponse = [];

    await this.geocoder.geocode({ placeId }, (results, status) => {
      // eslint-disable-next-line no-undef
      if (status === google.maps.GeocoderStatus.OK) {
        resultResponse = results;
      }
    });

    return resultResponse;
  }
}
