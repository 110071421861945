import { useStore } from 'vuex';
import { ref, computed } from 'vue';
import { useMutation } from './useMutation';
import { apiClient } from '../services/apiClient';
import { SubscriptionActionType } from '../constants';

export function useMembershipContext(subscriptionId) {
  const store = useStore();
  const mutationId = ['subscriptions', subscriptionId];

  const cancelMutate = useMutation(mutationId, async () => {
    const response = await apiClient.post('/cancel', {
      subscriptionId,
    });

    return response.data;
  });
  const pauseMutate = useMutation(mutationId, async (pauseDuration) => {
    const response = await apiClient.post('/pause', {
      subscriptionId,
      pauseLength: pauseDuration,
    });
    return response.data;
  });
  const resumeMutate = useMutation(mutationId, async () => {
    const response = await apiClient.post(`/subscriptions/${subscriptionId}/resume`);
    return response.data;
  });
  const reactivateMutate = useMutation(mutationId, async () => {
    const response = await apiClient.post(`/subscriptions/${subscriptionId}/reactivate`);
    return response.data;
  });
  const createMutate = useMutation('subscriptions.create', async () => {
    const response = await apiClient.post('/subscriptions', {
      customer_id: store.getters.getCustomerId(),
      shippingAddress: store.state.account.customer.shippingAddress,
    });
    const subscription = response.data;

    await store.dispatch('getAccount');

    return { [subscription.id]: subscription };
  });
  const upgradeMutate = useMutation(mutationId, async () => {
    const response = await apiClient.post('/upgrade', {
      shippingAddress: store.state.account.customer.shippingAddress,
      customer_id: store.state.account.customer.id,
      subscription: store.getters.currentSubscriptions[0],
    });

    const isDisplayUpdatesVariant = computed(() => store.getters.exp_001_isDisplayUpdatesVariant);
    const description = computed(() => (isDisplayUpdatesVariant.value ? 'UpgradeSub_DisplayExpVariant' : 'UpgradeSub_DisplayExpControl'));

    store.dispatch('updateMemberPrepaid', { description: description.value });

    return response.data;
  });

  const updateTaxRateMutate = useMutation(mutationId, async (options) => {
    const { price = null, address = null } = options || {};

    const response = await apiClient.post(`/subscriptions/${subscriptionId}/update-tax-rate`, {
      customer_id: store.state.account.customer.id,
      address,
      price,
    });

    return response.data;
  });

  const subscription = computed(() => store.getters.currentSubscriptions.find((sub) => sub.id === subscriptionId));
  const membershipState = ref({
    prepaid: computed(() => !!store.state.account.prepaidCustomer?.isPrePaid),
    paused: computed(() => !!subscription.value.pause_collection),
    canceled: computed(() => !!subscription.value.cancel_at_period_end),
    unpaid: computed(() => subscription.value.status === 'unpaid'),
    prepaidOneYear: computed(() => subscription.value.plan?.interval === 'year'),
    subscription,
  });

  return {
    [SubscriptionActionType.PAUSE]: pauseMutate,
    [SubscriptionActionType.CANCEL]: cancelMutate,
    [SubscriptionActionType.RESUME]: resumeMutate,
    [SubscriptionActionType.REACTIVATE]: reactivateMutate,
    [SubscriptionActionType.CREATE]: createMutate,
    [SubscriptionActionType.UPGRADE]: upgradeMutate,
    [SubscriptionActionType.UPDATE_TAX_RATE]: updateTaxRateMutate,
    membershipState,
  };
}
