import { useStore } from 'vuex';
import { computed } from 'vue';
import { Actions } from '../store/Actions';

export function useMutation(mutationId, asyncHandler) {
  const store = useStore();
  const isLoading = computed(() => store.getters.isAsyncDataLoading(mutationId));
  const data = computed(() => store.getters.getAsyncData(mutationId));
  const error = computed(() => store.getters.getAsyncDataError(mutationId));
  const mutate = (...args) => store.dispatch(Actions.MUTATE_ASYNC, {
    id: mutationId,
    handler: () => asyncHandler(...args),
  });

  return {
    isLoading,
    data,
    error,
    mutate,
  };
}
