import { useStore } from 'vuex';
import { formatUnixToMonthYear, formatPrice } from './helpers';

function statusColor(status) {
  const statuses = ['active', 'trialing'];

  return statuses.includes(status) ? status : 'other-standing';
}

function getStatus(status) {
  const stat = status === 'trialing' ? 'No Payment Due' : status;

  return stat?.split('_').map((item) => item.charAt(0).toUpperCase() + item.substring(1)).join(' ');
}

export function getTaxedPrice(sub) {
  const subItem = sub?.items?.data?.find((i) => i.object === 'subscription_item');
  const subItemTax = subItem?.tax_rates ? subItem.tax_rates.percentage : undefined;
  const { percentage } = sub?.default_tax_rates?.find((i) => i.object === 'tax_rate') || {};

  let formattedPrice;
  let subPrice = sub?.items?.data[0]?.plan?.amount;

  if (subItemTax || percentage) {
    const percentAmount = subItemTax ? (subPrice * subItemTax) / 100 : (subPrice * percentage) / 100;
    formattedPrice = formatPrice(subPrice += percentAmount);

    return formattedPrice;
  }

  formattedPrice = formatPrice(subPrice);

  return formattedPrice;
}

function getMembershipData({ subState, sub }) {
  const store = useStore();

  const interval = () => {
    if (sub.plan.interval === 'year') {
      return 'year';
    }

    if (sub.plan.interval === 'month' && sub.plan.interval_count > 1) {
      return `${sub.plan.interval_count} months`;
    }

    return 'month';
  };

  const title = `${getTaxedPrice(sub)} per ${interval()}`;

  switch (subState) {
    case 'paused': {
      const pauseResumeDate = formatUnixToMonthYear(sub?.pause_collection?.resumes_at);
      return {
        title,
        statusColor: 'paused',
        membershipStatus: getStatus('Paused'),
        subCopy: `You have paused your membership with us. Your subscription will resume on ${pauseResumeDate}.`,
      };
    }
    case 'prepaid': {
      const prepaidRemaining = store.state.account.prepaidCustomer.monthsRemaining;
      const subCopy = prepaidRemaining === null ? 'Your membership has expired.' : `You have ${prepaidRemaining} months remaining.`;
      return {
        title: 'Yearly Pre-Paid Membership',
        statusColor: statusColor(sub.status),
        membershipStatus: getStatus(sub.status),
        subCopy,
      };
    }
    case 'canceled': {
      const billingEndDate = formatUnixToMonthYear(sub?.current_period_end);
      return {
        title,
        statusColor: statusColor(sub.status),
        membershipStatus: getStatus(sub.status),
        subCopy: `You have canceled your membership with us. Your subscription will end ${billingEndDate}  <span style="display: inline-block; margin-top: 0.5rem; color: #4a90e2;">
        Looking to sell or gift your trackers? <a v-track-click="'Membership_SellGiftTrackers'" target="_blank" rel="noopener noreferrer" href="https://support.joinfightcamp.com/hc/en-us/articles/6356695109915-Selling-Your-FightCamp-Punch-Trackers-" style="color: #4a90e2;">Learn how</a>.
      </span>`,
      };
    }
    case 'unpaid':
      return {
        title,
        statusColor: statusColor(sub.status),
        membershipStatus: getStatus(sub.status),
        subCopy: 'Please <a target="_blank" rel="noopener noreferrer" href="https://support.joinfightcamp.com">contact</a> customer support to review your membership status.',
      };
    default: {
      const billingEndDate = formatUnixToMonthYear(sub?.current_period_end);
      return {
        title,
        statusColor: statusColor(sub.status),
        membershipStatus: getStatus(sub.status),
        subCopy: `Next invoice on ${billingEndDate}`,
      };
    }
  }
}

export {
  getMembershipData,
};
