<template>
  <div class="membership-details">
    <div class="membership-monthly">
      <p class="membership-price">{{ subData.title }}</p>
      <span class="membership-status" :class="subData.statusColor">{{ subData.membershipStatus }}</span>
        {{ subData.membershipState }}
    </div>
    <p v-html="subData.subCopy" class="membership-text" />
  </div>
</template>

<script>
import { computed } from 'vue';
import { useMembershipContext } from '../composables/useMembershipContext';
import { getMembershipData } from '../utils/membershipHelpers';

export default {
  name: 'MembershipText',
  props: {
    subId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { membershipState } = useMembershipContext(props.subId);

    const subData = computed(() => {
      switch (true) {
        case !!membershipState.value.paused:
          return getMembershipData({ subState: 'paused', sub: membershipState.value.subscription });
        case !!membershipState.value.canceled:
          return getMembershipData({ subState: 'canceled', sub: membershipState.value.subscription });
        case !!membershipState.value.unpaid:
          return getMembershipData({ subState: 'unpaid', sub: membershipState.value.subscription });
        case !!membershipState.value.prepaid:
          return getMembershipData({ subState: 'prepaid', sub: membershipState.value.subscription });
        default:
          return getMembershipData({ subState: 'default', sub: membershipState.value.subscription });
      }
    });

    return {
      subData,
    };
  },
};
</script>

<style lang="scss" scoped>
  .membership {
    &-info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: 2.5rem;

      @media #{$tablet-up} {
        flex-direction: row;
        width: 100%;
      }
    }

    &-details {
      @media #{$tablet-up} {
        max-width: 50%;
      }
    }

    &-monthly {
      display: flex;
    }

    &-price {
      font-size: 1.25rem;
      margin: 0;
    }

    &-status {
      padding: .1875rem .4375rem;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      margin: .375rem .75rem;
      border-radius: 1rem;
      font-size: 0.75rem;
      color: $color-white;
      height: max-content;

      &.active {
        background-color: $color-system-green;
        border: .0625rem solid $color-system-green;
      }

      &.trialing {
        background-color: $color-system-blue;
        border: .0625rem solid $color-system-blue;
      }

      &.other-standing {
        background-color: $color-system-red;
        border: .0625rem solid $color-system-red;
      }

      &.paused {
        background-color: $color-dark-gray;
        border: .0625rem solid $color-dark-gray;
      }
    }

    &-loading {
      padding: .1875rem .4375rem;
      text-align: center;
      display: inline-block;
      margin: .375rem 0rem;
      font-family: $font-stack-bold;
      color: $color-system-green;
      height: max-content;
    }

    &-text {
      margin-top: 0;
      font-size: .875rem;
      color: $color-light-gray-3;

      a {
        color: $color-system-blue;
      }
    }

    &-button-container {
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      @media #{$tablet-up} {
        flex-direction: row;
        margin-top: 0.3125rem;
        margin-left: auto;
        justify-content: flex-end;
      }

      .button-layout {
        flex-basis: 49%;
        margin: 0.5rem 0;

        @media #{$tablet-up} {
          flex-basis: auto;
          align-self: baseline;
          margin: 0rem 0rem 0rem 0.5rem;
        }
      }
    }
  }
  </style>
