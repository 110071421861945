import { Actions } from './Actions';
import { Mutations } from './Mutations';
import { SegmentWrapper } from '../plugins/Segment/segment';

function parseMutationId(id) {
  return Array.isArray(id) ? id : id.split('.');
}

function encodeMutationId(id) {
  return Array.isArray(id) ? id.join('.') : id;
}

function setData(state, id, data) {
  state.asyncData = state.asyncData || {};
  let cursor = state.asyncData;

  parseMutationId(id).forEach((key, index, parsedKeys) => {
    if (parsedKeys.length - 1 === index) {
      cursor[key] = data;
    } else {
      cursor[key] = cursor[key] || {};
      cursor = cursor[key];
    }
  });
}

export const asyncVuexAdapter = {
  mutations: {
    [Mutations.SET_MUTATION_START]: (state, { id }) => {
      state.asyncLoading = state.asyncLoading || {};
      state.asyncLoading[encodeMutationId(id)] = true;
    },
    [Mutations.SET_MUTATION_END]: (state, { id, data }) => {
      const encodedId = encodeMutationId(id);

      state.asyncLoading = state.asyncLoading || {};
      state.asyncErrors = state.asyncErrors || {};
      state.asyncLoading[encodedId] = false;
      state.asyncErrors[encodedId] = null;

      setData(state, id, data);
    },
    [Mutations.SET_MUTATION_ERROR]: (state, { id, error }) => {
      const encodedId = encodeMutationId(id);

      state.asyncLoading = state.asyncLoading || {};
      state.asyncErrors = state.asyncErrors || {};
      state.asyncLoading[encodedId] = false;
      state.asyncErrors[encodedId] = error;

      setData(state, id, null);
    },
  },
  actions: {
    [Actions.MUTATE_ASYNC]: async ({ commit }, { id, handler }) => {
      commit(Mutations.SET_MUTATION_START, { id });

      try {
        const data = await handler();
        commit(Mutations.SET_MUTATION_END, { id, data });
      } catch (error) {
        SegmentWrapper.trackError({ caller: 'Async Handler', error });

        if (error.response) {
          error.response.data.message = `${error.response.data.message} Please try again or <a target="_blank" rel="noopener noreferrer" href="https://support.joinfightcamp.com/hc/en-us">contact</a> support with any questions.`;

          commit(Mutations.SET_MUTATION_ERROR, { id, error: error.response.data.message });
        } else {
          commit(Mutations.SET_MUTATION_ERROR, { id, error });
        }
      }
    },
  },
  getters: {
    isAsyncDataLoading: (state) => (id) => {
      const encodedId = Array.isArray(id) ? id.join('.') : id;

      return !!state.asyncLoading && state.asyncLoading[encodedId];
    },
    getAsyncDataError: (state) => (id) => {
      const encodedId = Array.isArray(id) ? id.join('.') : id;

      return !!state.asyncErrors && state.asyncErrors[encodedId];
    },
    getAsyncData: (state) => (id) => {
      const parsedKeys = parseMutationId(id);

      return parsedKeys
        .reduce((cursor, key) => (cursor && cursor[key]), state.asyncData);
    },
  },
};
