<template>
  <div>
    <p v-if="isAnnualCustomer" class="annual-upsell__annual-customer-text" v-track-view="'AnnualUpsellYearly'">
      You are currently on a {{ monthlyPrice }}/{{ interval }} annual plan.
    </p>
    <div v-else v-track-view="'AnnualUpsellMonthly'">
        <p class="annual-upsell__monthly-customer-text">
          You are currently on a {{ monthlyPrice }}/{{ interval }} plan. You are eligible to save 10% with annual billing:
        </p>
        <AnnualMembershipCard/>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import AnnualMembershipCard from './AnnualMembershipCard.vue';
import { useSubscriptionDetails } from '../composables/useSubscriptionDetails';

export default {
  name: 'AnnualUpsell',
  components: {
    AnnualMembershipCard,
  },
  setup() {
    const store = useStore();
    const isAnnualCustomer = computed(() => store.getters.isAnnualCustomer);
    const { monthlyPrice, interval } = useSubscriptionDetails();

    return {
      isAnnualCustomer,
      monthlyPrice,
      interval,
    };
  },
};
</script>

<style lang="scss" scoped>
.annual-upsell {
  &__annual-customer-text {
    padding-bottom: 2rem;
  }

  &__monthly-customer-text {
    padding-bottom: 0rem;
  }
}

</style>
