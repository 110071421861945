function getTraits() {
  if (!window.analytics || !window.analytics.user) {
    return {};
  }
  const user = window.analytics.user();
  if (!user || !user.traits) {
    return {};
  }
  return user.traits();
}

function getSegment() {
  return {
    track: (name, props, options) => window.analytics.track(name, props, { ...options, traits: getTraits() }),
    page: window.analytics.page,
    identify: window.analytics.identify,
  };
}

export const SegmentWrapper = {
  experiment: ({ experimentId, variantId }) => {
    getSegment().track('Experiment', { experimentId, variantId });
  },

  elementViewed: (name) => {
    getSegment().track('Element Viewed', { name });
  },

  elementClicked: (name) => {
    getSegment().track('Element Clicked', { name });
  },

  trackEvent: (eventName, props) => {
    getSegment().track(eventName, { props });
  },

  // Vue router
  pageViewed: (route) => {
    getSegment().page(route.name, { ...getUTMQueryParams() });
  },

  // Identifications
  UIDIdentified: (uid) => {
    getSegment().identify(uid);
  },

  emailIdentified: (email) => {
    getSegment().identify({
      email,
    });
  },

  nameIdentified: ({ firstName, lastName }) => {
    getSegment().identify({
      firstName,
      lastName,
    });
  },

  phoneNumberIdentified: (phone) => {
    getSegment().identify({
      phone,
    });
  },

  reactivateUser: (email) => {
    getSegment().track('Customer Dashboard | Reactivation', { email });
  },

  // Customer Dashboard
  updatePaymentMethod: (paymentType) => {
    getSegment().track('Update Payment Method', { type: paymentType });
  },

  trackError: (props) => {
    getSegment().track('Internal Error', { ...props });
  },
};

export function getUTMQueryParams() {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const allowedParams = ['utm_campaign', 'utm_medium', 'utm_source', 'utm_term', 'utm_content'];

  const utms = {};

  urlSearchParams.forEach((value, key) => {
    if (allowedParams.includes(key)) {
      utms[key] = value;
    }
  });

  return utms;
}
