class PaymentHelperError extends Error {
  constructor(message) {
    super(message);
    this.message = message;
    this.name = 'PaymentHelperError';
  }
}

function errorHandler(err) {
  if (err instanceof PaymentHelperError) {
    throw err;
  }
  if (err) {
    throw new PaymentHelperError(err.message);
  }
  throw new PaymentHelperError('Something went wrong. Please verify the info and try again.');
}

function createTokenData(_data) {
  const data = _data;

  data.tokenData = {};
  data.tokenData.name = _data.card.name;
  data.tokenData.address_line1 = data.card.address_line1;
  data.tokenData.address_line2 = data.card.address_line2;
  data.tokenData.address_city = data.card.address_city;
  data.tokenData.address_state = data.card.address_state;
  data.tokenData.address_zip = data.card.address_zip;
  data.tokenData.address_country = data.card.country;

  return data;
}

function createStripeToken(_data) {
  const data = _data;

  return data.stripe.createToken(data.card, data.tokenData)
    .then((result) => {
      if (result.error) {
        throw new PaymentHelperError(result.error.message);
      }

      data.stripeToken = result.token;
      return data;
    });
}

export default {
  async addStripeToken(token, data) {
    const updatedData = createTokenData(data);
    updatedData.stripeToken = token;

    return updatedData;
  },
  createToken(_data) {
    return Promise.resolve(_data)
      .then(createTokenData)
      .then(createStripeToken)
      .catch(errorHandler);
  },
};
