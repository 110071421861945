import { useAnalytics } from './Segment';
import { loadGrowthBook } from '../services/growthbook';

export const growthBookKey = Symbol('growthbook');

export const growthBookPlugin = {
  async install(app) {
    let growthBook = null;
    const analytics = useAnalytics();

    window.analytics.ready(async () => {
      if (growthBook) {
        return growthBook;
      }

      growthBook = loadGrowthBook({
        trackExperiment: analytics.experiment,
        dispatch: app.config.globalProperties.$store.dispatch,
      });

      app.provide(growthBookKey, growthBook);
    });
  },
};
