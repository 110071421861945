<template>
    <div class="link-subscription-card" v-track-view="'LinkSubscriptionCard'">
      <div>
        <h2 class="link-subscription-card__title">Link subscription to your account</h2>
        <p class="link-subscription-card__text">If you have a subscription under a different email, we will send an email to that address to facilitate linking your account.</p>
      </div>
      <Modal>
        <template v-slot:trigger="slotProps">
          <button v-track-click="'LinkSubscriptionToAccount'" class="link-subscription-card__button button-normal button-layout button-cta" @click="slotProps.showModal()">
            Link Account
          </button>
        </template>
        <template v-slot:modal-content="slotProps">
          <LinkAccountEmailForm @closeModal="slotProps.hideModal()" />
        </template>
      </Modal>
    </div>
  </template>

<script>
import Modal from './Modal.vue';
import LinkAccountEmailForm from './LinkAccountEmailForm.vue';

export default {
  name: 'LinkSubscriptionCard',
  components: {
    Modal,
    LinkAccountEmailForm,
  },
};
</script>

<style lang="scss" scoped>
.link-subscription-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0.25rem 0.5rem rgba(15, 30, 60, 0.1);
  border-radius: $input-radius;
  background-color: $color-blue;
  color: $color-white;
  padding: 0 1.25rem;
  margin-bottom: 5rem;
  max-width: 800px;
  text-align: center;
  margin: 0 auto;

  @media #{$tablet} {
    padding: 0 4rem;
  }

  &__title {
    margin: 2rem 0;
  }

  &__text {
    margin: 0rem 0rem 1rem;
  }

  &__sub-text {
    margin: 0rem;
  }

  &__button {
    margin: 2rem 0rem;
    padding: 1rem;
  }
}

</style>
