<template>
  <section class="memberships">
    <SectionHeading text="Membership" />
    <div class="memberships__container">
      <Membership v-if="sub" :subId="sub.id" />
      <div v-else-if="hasOwnership" class="memberships__restart">
        <Modal hide-close-button>
          <template v-slot:trigger="slotProps">
            <button class="button-normal button-layout button-cta" @click="slotProps.showModal()">
              Restart Membership
            </button>
          </template>
          <template v-slot:modal-content="slotProps">
            <ReviveSubscriptionOverlay
              :action-type="createActionType"
              @closeModal="slotProps.hideModal()"
            />
          </template>
        </Modal>
      </div>
      <p v-else>Your membership is canceled. Only the account owner can reactivate it.</p>
      <p class="memberships_contact">Something doesn't look right? Please <a target="_blank" rel="noopener noreferrer" href="https://support.joinfightcamp.com">contact</a> customer support.</p>
    </div>
  </section>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import Membership from './Membership.vue';
import Modal from './Modal.vue';
import SectionHeading from './SectionHeading.vue';
import ReviveSubscriptionOverlay from './ReviveSubscriptionOverlay.vue';
import { SubscriptionActionType } from '../constants';

export default {
  name: 'Memberships',
  components: {
    ReviveSubscriptionOverlay,
    Membership,
    SectionHeading,
    Modal,
  },
  setup() {
    const store = useStore();
    const sub = computed(() => store.getters.currentSubscriptions[0]); // we only display the latest subscription
    const hasOwnership = computed(() => store.getters.hasOwnership);

    return {
      sub,
      createActionType: SubscriptionActionType.CREATE,
      hasOwnership,
    };
  },
};
</script>

<style lang="scss" scoped>
  .memberships {
    &__restart {
      display: flex;

      button {
        width: 100%;
      }
    }

    &__announcement {
      background-color: $color-blue;
      color: $color-white;
      border-radius: $input-radius;
      padding: .5rem 2rem;
      margin: 1rem 0;
    }

    &__contact {
      font-size: .5rem;
      margin-top: 2.5rem;
    }
  }
</style>
