<template>
  <div class="annual-membership-card" v-track-view="'AnnualMembershipCard_DisplayExpVariant'">
    <div>
      <h2 class="annual-membership-card__title">Annual Membership</h2>
      <p class="annual-membership-card__text">$35.10/month (billed annually)</p>
      <p class="annual-membership-card__sub-text">10% Off Your Membership Price</p>
      <p class="annual-membership-card__sub-text">Unlocked Interactivity & Workout Modes</p>
      <p class="annual-membership-card__sub-text">Exclusive Badges & Achievements</p>
      <p class="annual-membership-card__sub-text">Create Multiple User Profiles</p>
      <p class="annual-membership-card__sub-text">Access to Solo & Community Challenges</p>
    </div>
    <Modal>
      <template v-slot:trigger="slotProps">
        <button v-track-click="'AnnualMembershipUpgrade_DisplayExpVariant'" class="annual-membership-card__button button-normal button-layout button-cta" @click="slotProps.showModal()">
          Switch to Annual
        </button>
      </template>
      <template v-slot:modal-content="slotProps">
        <Upgrade
          @closeModal="slotProps.hideModal()"
          v-bind="selectedActionProps" />
      </template>
    </Modal>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import Modal from './Modal.vue';
import Upgrade from './Upgrade.vue';

export default {
  name: 'AnnualMembershipCard',
  components: {
    Modal,
    Upgrade,
  },
  setup() {
    const store = useStore();
    const currentSubscriptionId = computed(() => {
      const [sub] = store.getters.currentSubscriptions || [];

      return sub?.id;
    });
    const selectedActionProps = computed(() => ({
      subId: currentSubscriptionId.value,
      actionType: 'Upgrade',
      isCancelClicked: false,
    }));

    return { selectedActionProps };
  },
};
</script>

<style lang="scss" scoped>
.annual-membership-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0.25rem 0.5rem rgba(15, 30, 60, 0.1);
  border-radius: $input-radius;
  background-color: $color-blue;
  color: $color-white;
  padding: 0 1.25rem;
  margin-bottom: 5rem;
  max-width: 800px;
  text-align: center;

  @media #{$tablet} {
    padding: 0 4rem;
  }

  &__title {
    margin: 2rem 0;
  }

  &__text {
    margin: 0rem 0rem 1rem;
  }

  &__sub-text {
    margin: 0rem;
  }

  &__button {
    margin: 2rem 0rem;
    padding: 1rem;
  }
}

</style>
