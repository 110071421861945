<template>
<div v-show="isWalletPaymentAvailable" class="wallet-payment">
  <div id="payment-request-button"/>
  <p v-if="isError" class="wallet-payment__errors">{{ error }}</p>
</div>
</template>

<script>
import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import paymentHelper from '../utils/paymentHelper';
import { useAnalytics } from '../plugins/Segment';

export default {
  name: 'WalletPayment',
  setup(_, { emit }) {
    const isWalletPaymentAvailable = ref(false);
    const stripe = window.Stripe(process.env.VUE_APP_STRIPE_KEY);
    const store = useStore();
    const isError = ref(false);
    const error = ref('');
    const analytics = useAnalytics();

    onMounted(() => {
      const paymentRequest = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: 'Payment total',
          amount: 0,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });
      const walletElement = stripe.elements();
      const payButton = walletElement.create('paymentRequestButton', {
        paymentRequest,
      });

      paymentRequest.canMakePayment()
        .then((result) => {
          if (result) {
            isWalletPaymentAvailable.value = true;
            emit('isWalletAvailable', true);
            payButton.mount('#payment-request-button');
            analytics.elementViewed('WalletPayment');
          } else {
            document.getElementById('payment-request-button').style.display = 'none';
          }
        })
        .catch(() => {
          isError.value = true;
          error.value = 'There was an error loading wallet payment. Please enter card below.';
        });

      paymentRequest.on('token', (result) => {
        if (result.error) {
          result.complete('fail');
        } else {
          result.complete('success');
          processPayment(result);
        }
      });
    });

    async function processPayment(result) {
      const data = {
        store,
        stripe,
        card: result.token.card,
      };

      const stripeTokenData = await paymentHelper.addStripeToken(result.token, data);
      const newCard = await stripe.createPaymentMethod({
        type: 'card',
        card: { token: stripeTokenData.stripeToken.id },
      });

      if (newCard.error) {
        store.commit('CREATE_CARD_ERROR', newCard.error);
        return;
      }

      const payload = {
        customerId: store.state.account.customer.id,
        paymentMethodId: newCard.paymentMethod.id,
      };

      analytics.updatePaymentMethod({ type: 'Wallet' });

      await store.dispatch('createCard', payload)
        .then(() => {
          emit('cardCreated');
        });
    }

    return { isWalletPaymentAvailable, isError, error };
  },
};
</script>

<style lang="scss" scoped>
.wallet-payment {
  &__errors {
    color: $color-red;
  }
}
</style>
