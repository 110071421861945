<template>
  <div>
    <WalletPayment @isWalletAvailable="isWalletAvailable" @cardCreated="cardCreated"/>
    <p v-if="isWalletText" class="credit-card-form__text">or pay with card</p>
    <div class="credit-card-form__container">
      <div id="credit-card-form-element" class="credit-card-form__element"/>
      <p v-if="errorMessage" class="credit-card-form__errors">{{errorMessage}}</p>
      <div class="credit-card-form__actions">
        <button
          v-track-click="`${$options.name}_SaveBtn`"
          @click="submitCard"
          class="credit-card-form__button button-layout"
          :class="isCardProcessing ? 'button-disabled' : 'button-cta'"
          :disabled="isCardProcessing"
          type="button">
          <font-awesome-icon icon="spinner" spin v-if="isCardProcessing"/>
          <span v-else>
            Save
          </span>
        </button>
        <button
          v-track-click="`${$options.name}_CancelBtn`"
          class="credit-card-form__button button-layout button-outline"
          @click="$emit('formCancelled')">
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, computed } from 'vue';
import { useStore } from 'vuex';
import { updateBillingWithNoPaymentOnFile, updateBillingWithPaymentOnFile } from '../utils/helpers';
import WalletPayment from './WalletPayment.vue';
import { useAnalytics } from '../plugins/Segment';
import { cardStyle } from '../constants/stripeElementStyles';

export default {
  name: 'CreditCardForm',
  components: {
    WalletPayment,
  },
  setup(_, { emit }) {
    const store = useStore();
    const analytics = useAnalytics();

    const error = ref('');
    const errorMessage = computed(() => error.value || store.state.updateCardError);

    const isCardProcessing = computed(() => store.state.requestInProgress);
    const creditCard = computed(() => store.state.account.paymentMethod);
    const account = computed(() => store.state.account);
    const isWalletText = ref(false);

    const stripe = window.Stripe(process.env.VUE_APP_STRIPE_KEY);
    const elements = stripe.elements();
    let card;

    onMounted(() => {
      card = elements.create('card', { style: cardStyle });
      card.on('change', (event) => {
        if (event.error) {
          error.value = event.error.message;
        } else {
          error.value = '';
        }
      });
      card.mount('#credit-card-form-element');
    });

    const submitCard = async () => {
      store.commit('CREATE_CARD_ERROR', '');
      const billingAddress = !creditCard.value ? updateBillingWithNoPaymentOnFile(account.value) : updateBillingWithPaymentOnFile(creditCard.value);
      const newCard = await stripe.createPaymentMethod({
        type: 'card',
        card,
        billing_details: billingAddress,
      });

      if (newCard.error) {
        store.commit('CREATE_CARD_ERROR', newCard.error.message);
        return;
      }
      store.commit('SET_CARD_ON_FILE_ERROR', '');

      const payload = {
        customerId: store.state.account.customer.id,
        paymentMethodId: newCard.paymentMethod.id,
      };

      analytics.updatePaymentMethod({ type: 'CreditCard' });

      await store.dispatch('createCard', payload);

      if (!errorMessage.value) {
        analytics.updatePaymentMethod({ type: 'CreditCard' });
        emit('cardCreated');
      }
    };

    function isWalletAvailable(boolean) {
      isWalletText.value = boolean;
    }

    return {
      submitCard,
      errorMessage,
      isCardProcessing,
      isWalletText,
      isWalletAvailable,
      cardCreated: (() => emit('cardCreated')),
    };
  },
};
</script>

<style lang="scss" scoped>
.credit-card-form {
  &__text {
    text-align: center;
    color: #91989e;
  }

  &__actions {
    display: flex;
  }

  &__button {
    margin: 0;
    min-width: 110px;
    margin: 1rem .5rem 0 0;
  }

  &__element {
    background-color: white;
    padding: 12px 12px;
    border: 1px solid $color-dark-gray;
    border-radius: $input-radius;
    color: $color-black;
    margin: 1rem 0;
  }

  &__errors {
    color: $color-red;
  }
}

.StripeElement--focus {
  border-color: $color-black;
}

.StripeElement--invalid {
  border-color: $color-accent;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
</style>
