<template>
  <form class="address-form" @submit.prevent="$emit('addressFormSubmitted', formData)">
    <div class="address-form-row">
      <p class="address-form-name">
        {{ name }}
      </p>
    </div>
    <div class="address-form-row">
      <AutoCompleteInput @setFields="setFields" :addressline1="formData.line1"/>
    </div>
    <div class="address-form-row">
      <FCInput
        v-model="formData.line2"
        class="address-form-input"
        name="address2"
        title="Address Line 2" />
    </div>
    <div class="address-form-row">
      <FCInput
        v-model="formData.city"
        class="address-form-input"
        name="city"
        title="City"
        :required="true" />
        <FCSelect
          id="state"
          v-model="formData.state"
          class="address-form-input"
          :modelValue="formData.state"
          name="state"
          hint="State"
          label="State/Province"
          autocomplete="address-level1"
          :required="true"
          :options="states" />
      <FCInput
        v-model="formData.postal_code"
        class="address-form-input"
        name="postalCode"
        title="Zip Code"
        :required="true" />
    </div>
    <div class="address-form-row">
      <FCSelect
        id="country"
        v-model="formData.country"
        class="address-form-input"
        :modelValue="formData.country"
        name="country"
        hint="Country"
        label="Country"
        autocomplete="country-name"
        :required="true"
        :options="countries" />
    </div>
    <div class="address-form-actions">
      <button
        class="address-form-button button-layout"
        :class="loading ? 'button-disabled' : 'button-cta'"
        :disabled="loading"
        v-track-click="`${$options.name}_SaveBtn`">
        <font-awesome-icon icon="spinner" spin v-if="loading"/>
        <span v-else>
          Save
        </span>
      </button>
      <button
        class="address-form-button button-layout button-outline"
        @click="$emit('addressFormCancelled')"
        v-track-click="`${$options.name}_CancelBtn`">
        Cancel
      </button>
    </div>
  </form>
</template>

<script>
import { reactive } from 'vue';
import FCInput from './FCInput.vue';
import FCSelect from './FCSelect.vue';
import states from '../constants/iso_state_code.json';
import countries from '../constants/iso_country_code.json';
import AutoCompleteInput from './AutoCompleteInput.vue';

export default {
  name: 'AddressForm',
  components: {
    FCInput,
    FCSelect,
    AutoCompleteInput,
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    address: {
      type: Object,
      default: () => ({
        line1: '',
        line2: '',
        city: '',
        state: '',
        postal_code: '',
        country: '',
      }),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const formData = reactive({ ...props.address });
    function setFields(fields) {
      // must use Object.assign to avoid reactivity issues since cannot directly modify a reactive object
      Object.assign(formData, fields);
    }

    return {
      formData,
      states,
      countries,
      setFields,
    };
  },
};
</script>

<style lang="scss" scoped>
  .address-form {
    &-row {
      margin-bottom: .5rem;

      @media #{$desktop} {
        display: flex;
      }
    }

    &-input {
      margin-bottom: .5rem;

      @media #{$desktop} {
        margin-right: .5rem;
      }
    }

    &-actions {
      display: flex;
    }

    &-button {
      margin: 0;
      min-width: 110px;
      margin: 1rem .5rem 0 0;
    }

    &-name {
      text-transform: capitalize;
    }
  }
</style>
