<template>
  <section class="payment-method">
    <SectionHeading text="Payment Method" />
    <div v-if="hasOwnership">
      <div v-if="editCard" class="payment-method-edit">
        <CreditCardForm @cardCreated="toggleEdit" @formCancelled="toggleEdit" />
      </div>
      <div v-else >
        <CreditCardDetails
          :brand="card.brand"
          :lastFour="card.last4"
          :expMonth="card.exp_month"
          :expYear="card.exp_year"
        />
        <IconAction
          v-track-click="`${$options.name}_UpdateBtn`"
          @buttonClicked="toggleEdit"
          label="Update payment method"
          icon="plus" />
      </div>
      <p v-if="cardError" class="payment-method__error">{{ cardError }}</p>
    </div>
    <p v-else>Only the account owner can make payment changes.</p>
  </section>
</template>

<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import CreditCardForm from './CreditCardForm.vue';
import CreditCardDetails from './CreditCardDetails.vue';
import IconAction from './IconAction.vue';
import SectionHeading from './SectionHeading.vue';

export default {
  name: 'PaymentMethod',
  components: {
    CreditCardDetails,
    CreditCardForm,
    IconAction,
    SectionHeading,
  },
  setup() {
    const store = useStore();
    const editCard = ref(false);
    const card = computed(() => (store.state.account.paymentMethod ? store.state.account.paymentMethod.card : {}));
    const cardError = computed(() => store.state.cardOnFileError);
    const hasOwnership = computed(() => store.getters.hasOwnership);

    const toggleEdit = () => {
      editCard.value = !editCard.value;
      store.commit('CREATE_CARD_ERROR', '');
    };

    return {
      toggleEdit,
      editCard,
      card,
      cardError,
      hasOwnership,
    };
  },
};
</script>

<style lang="scss" scoped>
.payment-method {
  &-edit {
      @media #{$desktop} {
      width: 60%;
    }
  }

  &__error {
    color: $color-red;
  }
}
</style>
