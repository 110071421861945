import { createApp } from 'vue';
import FontAwesomeIcon from './iconsLibrary';
import { FCAnalytics } from './plugins/Segment';
import { auth0 } from './plugins/auth';
import App from './App.vue';
import store from './store';
import { router } from './routes';
import { growthBookPlugin } from './plugins/growthbook';
import { googleMapsPlugin } from './plugins/googlemaps';

const app = createApp(App);

app.use(router)
  .use(auth0)
  .use(store)
  .use(FCAnalytics, {
    writeKey: process.env.VUE_APP_SEGMENT,
  })
  .component('font-awesome-icon', FontAwesomeIcon)
  .use(growthBookPlugin)
  .use(googleMapsPlugin)
  .mount('#app');
