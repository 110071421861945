<template>
  <div class="pause__mask">
    <div class="pause__wrapper">
      <div class="pause__container">
        <div class="pause__header">
          <h2 class="pause__title" v-if="isPaused && !pauseError">Pause Confirmed</h2>
          <h2 class="pause__title" v-else >Pause Options</h2>
        </div>
        <div v-if="isPaused && !pauseError && !requestInProgress" class="pause__body">
          <p v-track-view="`${$options.name}_Modal_Confirmation`">Your subscription has been paused and your access to the FightCamp
            workouts has been revoked. It will be reactivated in {{ pauseDurationInMonths }}.
            Should you need access to your FightCamp workouts before then,
            <a target="_blank" rel="noopener noreferrer" href="https://support.joinfightcamp.com/hc/en-us">contact</a> support
            to reactivate your subscription sooner.
          </p>
          <button
            class="button-layout button-outline"
            v-track-click="`${$options.name}_CloseBtn`"
            @click="$emit('closeModal')">
            Okay
          </button>
        </div>
        <div v-else class="pause__body">
          <h3 v-if="pauseError" v-html="pauseError" />
          <p v-track-view="`${$options.name}_Modal`"> Traveling, moving, injured, or otherwise unable to workout for a
            certain period of time? Pause your subscription here. Your access to
            the FightCamp workouts will be revoked for this period of time.
          </p>
          <div class="select__container">
            <select
            @change="trackOptionChange"
            required
            class="select__options"
            v-model="selected">
              <option
                v-for="option in pauseTimeOptions"
                :key="option.key"
                :value="option.key">
                {{ option.name }}
              </option>
            </select>
            <label class="select__label">
              Please select one
            </label>
            <div class="select__loading" v-if='requestInProgress'>
              <font-awesome-icon icon="spinner" size="2x" spin />
            </div>
            <button v-else
              v-track-click="`Membership_PauseConfirmationBtn`"
              class="pause__button-confirm button-layout button-outline"
              type="button"
              @click='handleClick(selected)'>
                Confirm
            </button>
          </div>
          <p v-if="isCancelClicked" class="pause__cancellation">
            Not looking to pause, continue to
            <button
              class="pause__button-cancel"
              type="button"
              @click="$emit('openCancelModal')">
              cancellation
            </button>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useAnalytics } from '../plugins/Segment';
import { useMembershipContext } from '../composables/useMembershipContext';

export default {
  name: 'Pause',
  setup(props) {
    const {
      isLoading,
      error,
      mutate,
    } = useMembershipContext(props.subId).pause;
    const analytics = useAnalytics();
    const isPaused = ref(false);
    const selected = ref('one_month');
    const pauseDurationInMonths = ref('');
    const pauseTimeOptions = ref(
      [
        {
          key: 'one_month',
          name: '1 month',
        },
        {
          key: 'two_months',
          name: '2 months',
        },
        {
          key: 'three_months',
          name: '3 months',
        },
      ],
    );

    async function handleClick(pauseDuration) {
      await mutate(pauseDuration);
      const pauseTimeObject = pauseTimeOptions.value.find((el) => el.key === pauseDuration);

      pauseDurationInMonths.value = pauseTimeObject.name;
      isPaused.value = true;
    }

    const trackOptionChange = () => {
      analytics.elementClicked(selected.value);
    };

    return {
      isPaused,
      selected,
      pauseTimeOptions,
      handleClick,
      pauseDurationInMonths,
      requestInProgress: isLoading,
      pauseError: error,
      trackOptionChange,
    };
  },
  props: {
    isCancelClicked: {
      type: Boolean,
      default: false,
    },
    subId: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.pause {
  &__mask {
    width: 100%;
  }

  &__title {
    margin: 0;
  }

  &__body {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__cancellation {
    text-align: center;

    @media #{$tablet} {
      align-self: center;
    }

    @media #{$desktop} {
      align-self: center;
    }
  }

  &__button {
    &-close {
      background: $color-white;
      border: none;
      font-size: 1rem;
    }

    &-cancel {
      color: $color-red;
      text-decoration: underline;
      background: none;
      border: none;
      font-size: 1rem;
      line-height: 1.5em;
    }

    &-confirm {
      margin: 1.25rem 0 0 0;
      width: 100%;
    }
  }
}

.select {
  &__loading {
    margin-top: 1.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }

  &__container {
    position: relative;
    width: 100%;
  }

  &__options {
    box-sizing: border-box;
    align-self: center;
    display: inline-block;
    line-height: 1.5em;
    appearance: none;
    position: relative;
    width: 100%;
    border-radius: $input-radius;
    border: $border-light-outline;
    background-color: white;
    font-size: 1rem;
    padding: .75rem 2.625rem .25rem 1.125rem;
    color: $color-black;
    background-size: .3125rem .3125rem, .3125rem .3125rem, .0625rem 1.5em;
    background-repeat: no-repeat;
    background-image:
      linear-gradient(45deg, transparent 50%, gray 50%),
      linear-gradient(135deg, gray 50%, transparent 50%),
      linear-gradient(to right, #ccc, #ccc);
    background-position:
      calc(100% - 1.25rem) calc(1em + .125rem),
      calc(100% - .9375rem) calc(1em + .125rem),
      calc(100% - 2.5em) 0.5em;

    &:focus {
      border: .0625rem solid $color-black;
    }
  }

  &__label {
    position: absolute;
    pointer-events: none;
    left: 1.25rem;
    top: .3125rem;
    transition: 0.2s ease all;
    color: $color-gray;
    font-size: .5rem;
  }
}
</style>
