export const fcLogo = {
  prefix: 'fc',
  iconName: 'logo',
  icon: [
    74,
    67,
    [],
    null,
    `M15.7562 27.9739H12.0364V6.29038H21.3207V9.52628H15.7562V15.6654H20.0808V18.9013H15.7562V27.9739Z
M18.266 52.6212H21.8043V55.4337C21.8695 56.137 21.7789 56.8461 21.5391 57.5105C21.2992 58.1749 20.9159 58.7783 20.4164 59.2778C19.917 59.7773 19.3136 60.1606 18.6492 60.4004C17.9849 60.6403 17.2758 60.7308 16.5725 60.6656C15.8608 60.7364 15.1422 60.6525 14.466 60.4195C13.7899 60.1866 13.172 59.8102 12.6549 59.3161C12.1378 58.822 11.7339 58.2219 11.4704 57.557C11.207 56.8921 11.0904 56.1781 11.1288 55.4639V43.7603C11.0904 43.0461 11.207 42.3321 11.4704 41.6672C11.7339 41.0023 12.1378 40.4022 12.6549 39.9081C13.172 39.414 13.7899 39.0376 14.466 38.8047C15.1422 38.5718 15.8608 38.4878 16.5725 38.5586C17.2758 38.4934 17.9849 38.584 18.6492 38.8239C19.3136 39.0638 19.917 39.447 20.4164 39.9465C20.9159 40.446 21.2992 41.0493 21.5391 41.7137C21.7789 42.3781 21.8695 43.0872 21.8043 43.7905V46.1796H18.266V43.7603C18.266 42.732 17.8124 41.8247 16.5725 41.8247C15.3326 41.8247 14.8789 42.732 14.8789 43.7603V55.6152C14.8789 56.6132 15.3326 57.5506 16.5725 57.5506C17.8124 57.5506 18.266 56.6132 18.266 55.6152V52.6212Z
M26.6434 6.29038H22.9236V27.9739H26.6434V6.29038Z
M45.5145 18.8711V27.9739H41.7947V6.29038H45.5145V15.6654H48.8411V6.29038H52.5608V27.9739H48.8411V18.8711H45.5145Z
M60.9983 27.9739H57.3088V9.52628H53.9822V6.29038H64.3552V9.52628H60.9983V27.9739Z
M74.3047 0H0V1.54235H74.3047V0Z
M39.6474 23.0142C39.7174 23.72 39.6302 24.4324 39.3925 25.1006C39.1548 25.7687 38.7723 26.376 38.2723 26.8789C37.7722 27.3819 37.1671 27.7679 36.5003 28.0095C35.8335 28.2511 35.1215 28.3422 34.4154 28.2763C33.6998 28.3471 32.9773 28.2624 32.2975 28.0279C31.6177 27.7934 30.9965 27.4147 30.4766 26.9178C29.9567 26.4209 29.5504 25.8176 29.2855 25.149C29.0205 24.4805 28.9032 23.7625 28.9416 23.0444V11.2198C28.9078 10.5041 29.0285 9.78959 29.2955 9.12475C29.5624 8.4599 29.9692 7.86039 30.4886 7.36687C31.0079 6.87335 31.6275 6.49745 32.3051 6.26473C32.9827 6.03201 33.7024 5.94785 34.4154 6.01813C35.1188 5.95291 35.828 6.0435 36.4923 6.28337C37.1567 6.52324 37.7601 6.90653 38.2595 7.406C38.759 7.90547 39.1423 8.50881 39.3822 9.1732C39.622 9.83758 39.7126 10.5467 39.6474 11.25V13.8508H36.1695V11.2198C36.1695 10.1915 35.7159 9.28432 34.476 9.28432C33.236 9.28432 32.7521 10.1915 32.7521 11.2198V23.0444C32.7521 24.0726 33.236 25.0101 34.476 25.0101C35.7159 25.0101 36.1695 24.0726 36.1695 23.0444V18.871H34.3248V15.8468H39.7078L39.6474 23.0142Z
M57.3389 60.3631H53.6192V38.6796H58.6998C59.4589 38.6365 60.2181 38.7584 60.9255 39.0369C61.6329 39.3154 62.2716 39.7438 62.7976 40.2928C63.3235 40.8417 63.7243 41.4981 63.9723 42.2168C64.2203 42.9354 64.3097 43.6994 64.2342 44.4559V45.9377C64.3091 46.6955 64.2197 47.4605 63.9719 48.1806C63.7241 48.9006 63.3237 49.5588 62.7983 50.11C62.2729 50.6612 61.6349 51.0925 60.9276 51.3746C60.2202 51.6566 59.4604 51.7827 58.6998 51.7442H57.3389V60.3631ZM57.3389 48.5083H58.4578C59.7885 48.5083 60.5446 47.5405 60.5446 45.9377V44.486C60.5446 42.8832 59.7885 41.9155 58.4578 41.9155H57.3389V48.5083Z
M34.3551 60.3631H30.605L30.0304 56.4317H26.7644L26.1898 60.3631H22.6816L26.0687 38.6796H30.9681L34.3551 60.3631ZM28.3067 44.3046L27.0364 53.6797H29.5466L28.3671 44.3046H28.3067Z
M39.4356 60.3934H35.8971V38.6796H41.0383L43.4576 52.5304L45.8771 38.6796H51.0181V60.3934H47.4799V54.345C47.4799 51.3208 47.7218 47.4498 47.873 44.3953L45.0605 60.5144H42.0363L39.2541 44.3953C39.2541 47.4195 39.5565 51.0788 39.5565 54.345L39.4356 60.3934Z
M74.3047 32.5707H0V34.1131H74.3047V32.5707Z
M74.3047 64.9599H0V66.5023H74.3047V64.9599Z`,
  ],
};
