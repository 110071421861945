<template>
  <div class="form-container">
    <input
      :id="name"
      :value="modelValue"
      class="form-input"
      :placeholder="placeholder"
      :name="name"
      :autocomplete="autocomplete"
      :disabled="disabled"
      :required="required"
      @input="$emit('update:modelValue', $event.target.value)"
      @invalid="invalidField">
    <label
      :for="name"
      class="form-label">{{ title }}</label>
  </div>
</template>

<script>
/*
  TODO add error handling of input
*/

export default {
  name: 'FCInput',
  props: {
    title: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: false,
      default: 'text',
    },
    modelValue: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    autocomplete: {
      type: String,
      required: false,
      default: 'off',
    },
  },
};
</script>

<style scoped lang="scss">

  .form-container {
    width: 100%;
    position: relative;
  }

  .form-input {
    position: relative;
    width: 100%;
    border-radius: $input-radius;
    background-color: white;
    box-sizing: border-box;
    border: $border-light-outline;
    font-size: 16px;
    padding: 12px 18px 4px 18px;
    margin: 0;
    color: #000;
    -webkit-appearance: none;
  }

  .form-label {
    position: absolute;
    pointer-events: none;
    left: 20px;
    top: 12px;
    transition: 0.2s ease all;
    color: $color-gray;
    font-size: 14px;
  }

  input:disabled ~ .form-label {
    top: 4px;
    bottom: 10px;
    font-size: 10px;
    opacity: 1;
  }

  input:focus ~ .form-label {
    top: 4px;
    bottom: 10px;
    font-size: 10px;
    opacity: 1;
  }

  input:not(:empty) ~ .form-label {
    top: 4px;
    bottom: 10px;
    font-size: 10px;
    opacity: 1;
  }

  input:not(:placeholder-shown) ~ .form-label {
    top: 4px;
    bottom: 10px;
    font-size: 10px;
    opacity: 1;
  }

  input:not(:focus):valid ~ .form-label {
    top: 4px;
    bottom: 10px;
    font-size: 10px;
    opacity: 1;
  }

  .form-input:disabled {
    background-color: $color_light_gray;
  }

  .form-input:focus {
    border: 1px solid $color-black;
  }
</style>
