import { useStore } from 'vuex';
import { computed } from 'vue';

export function useAccount() {
  const store = useStore();

  return {
    card: computed(() => store.state.account.paymentMethod),
    name: computed(() => store.state.account.customer.name),
    email: computed(() => store.state.account.customer.email),
  };
}
