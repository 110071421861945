import axios from 'axios';
import { getAccessTokenSilently } from '../plugins/auth';

const apiClient = axios.create({
  withCredentials: false, // This is the default
  baseURL: process.env.VUE_APP_API_ENDPOINT,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

/** Set Bearer token to all requests */
apiClient.interceptors.request.use(async (_config) => {
  const token = await getAccessTokenSilently();
  if (!token) {
    return _config;
  }

  const config = _config;
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

export {
  apiClient,
};
