<template>
  <div class="creditCard-details">
    <div v-if="hasPaymentMethod" class="creditCard-details-info">
      <div class="creditCard-details-card-number">
        <span class="creditCard-details-card-type">
          {{brand}}
        </span>
        <p class="creditCard-details-last-four">
          <span class="creditCard-details-placeholder">
            &centerdot;&centerdot;&centerdot;&centerdot;
          </span>
          {{lastFour}}
        </p>
      </div>
      <p>Expires {{expMonth}}/{{expYear}}</p>
    </div>
    <div v-else>
      <p class="creditCard-details-no-payment">
        You don't have a payment method on file. Please click the button below to add one.
      </p>
    </div>

  </div>
</template>

<script>
export default {
  name: 'CreditCardDetails',
  props: {
    brand: {
      type: String,
      default: '',
    },
    lastFour: {
      type: String,
      default: '',
    },
    expMonth: {
      type: Number,
      default: null,
    },
    expYear: {
      type: Number,
      default: null,
    },
  },
  computed: {
    hasPaymentMethod() {
      return this.brand && this.lastFour && this.expMonth && this.expYear;
    },
  },
};
</script>

<style lang="scss" scoped>
.creditCard-details {
  &-info {
    display: flex;
    margin: 1rem 0;
    align-items: center;
  }

  &-last-four {
    margin: 0;
  }

  &-card-number {
    display: flex;
    align-items: center;
    flex-basis: 180px;
  }

  &-card-type {
    padding-right: 1rem;
    text-transform: capitalize;

    svg {
      font-size: 28px;
      color: $color-dark-gray-3;
    }
  }

  &-no-payment {
    margin: 0;
  }
}
</style>
