import { computed } from 'vue';
import { useStore } from 'vuex';
import { formatPrice } from '../utils/helpers';

export function useSubscriptionDetails() {
  const store = useStore();
  const sub = computed(() => store.getters.currentSubscriptions[0]);

  const {
    amount,
    interval,
    interval_count: intervalCount,
  } = sub?.value?.plan ?? {};

  return {
    monthlyPrice: amount ? formatPrice(amount / intervalCount) : null,
    interval: interval ?? null,
  };
}
