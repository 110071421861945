import { apiClient } from '../../services/apiClient';

const INVALID_LINK_ERROR = 'Your link is invalid or has expired. Please close this window and request a new link in the Dashboard.';

export async function validateAndLinkAccount({ state, commit }, { emails, key }) {
  if (!emails || !key) {
    commit('LINKING_EMAIL_RESPONSE', INVALID_LINK_ERROR);
    return;
  }

  const emailsList = JSON.parse(decodeURIComponent(emails));
  const originalEmailLinkingRequest = emailsList.linkingRequestEmail;
  const { accountOwnerEmail } = emailsList;

  if (!originalEmailLinkingRequest || !accountOwnerEmail) {
    commit('LINKING_EMAIL_RESPONSE', INVALID_LINK_ERROR);
    return;
  }

  const doesEmailMatch = originalEmailLinkingRequest === state.user.email;
  if (!doesEmailMatch) {
    commit('LINKING_EMAIL_RESPONSE', 'Please log in as the person requesting to be linked.');
    return;
  }

  try {
    const resp = await apiClient.post('/account/link', { email: accountOwnerEmail, key, linkingRequestEmail: originalEmailLinkingRequest });
    commit('LINKING_EMAIL_RESPONSE', `${resp.data.message}. You can now close this window.`);
  } catch (error) {
    if (error.response.status === 400 && error.response.data.error === 'Invalid link') {
      commit('LINKING_EMAIL_RESPONSE', INVALID_LINK_ERROR);
      return;
    }

    commit('LINKING_EMAIL_RESPONSE', 'Something went wrong. Please contact customer support.');
  }
}
