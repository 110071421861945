<template>
    <slot name="trigger" :show-modal="showModal"></slot>
    <teleport to="#modal-teleport">
      <transition name="fade" mode="out-in">
        <div class="modal" v-if="modalShown">
          <div class="modal__overlay"></div>
          <div class="modal__container">
            <div class="modal__wrapper">
              <div class="modal__right-accessory" v-if="!hideCloseButton">
                <button class="modal__close" @click="hideModal()">
                  <font-awesome-icon icon="times" size="lg"/>
                </button>
              </div>
              <slot name="modal-content" :hide-modal="hideModal"></slot>
            </div>
          </div>
        </div>
      </transition>
    </teleport>
</template>

<script>
import { ref, watchEffect } from 'vue';

export default {
  name: 'Modal',
  props: {
    hideCloseButton: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const modalShown = ref(false);

    watchEffect((onInvalidate) => {
      if (modalShown.value) {
        document.body.classList.add('no-scroll');
      } else {
        document.body.classList.remove('no-scroll');
      }

      onInvalidate(() => {
        document.body.classList.remove('no-scroll');
      });
    });

    return {
      modalShown,
      showModal() {
        modalShown.value = true;
      },
      hideModal() {
        modalShown.value = false;
      },
    };
  },
};
</script>

<style lang="scss" scoped>
  .modal {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;

    &__overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(#000000, 0.6);
    }

    &__container {
      width: 100%;
      margin: 0 auto;
      max-width: 900px;
      height: 100%;
      overflow-y: auto;
      padding: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__wrapper {
      width: 100%;
      position: relative;
      overflow: hidden;
      background: $color-white;
      padding: 1.5rem;
      border-radius: 2px;
    }

    &__right-accessory {
      position: absolute;
      top: 0.2rem;
      right: 0.2rem;
    }

    &__close {
      background: none;
      border: 0;
    }
  }

  .fade {
    &-enter-active,
    &-leave-active {
      transition: opacity 0.3s ease-in;
    }

    &-enter-from,
    &-leave-to {
      opacity: 0;
    }
  }
</style>
