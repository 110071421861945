<template>
    <div class="link-account">
        <div class="link-account__loading" v-if="isLoading">
            <font-awesome-icon icon="spinner" size="3x" spin/>
        </div>
        <div class="link-account__copy" v-else>
            <p v-if="message || error">{{ message || error }}</p>
        </div>
    </div>
</template>

<script>
import {
  ref, onMounted, onUnmounted, computed,
} from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

export default {
  name: 'LinkAccount',
  setup() {
    const isLoading = ref(true);
    const error = ref('');
    const { key = null, emails = null } = useRoute().query;
    const store = useStore();

    let timeoutId;
    onMounted(async () => {
      await store.dispatch('validateAndLinkAccount', { emails, key });
      isLoading.value = false;

      timeoutId = setTimeout(() => {
        if (isLoading.value) {
          isLoading.value = false;
          error.value = 'Error linking account. Please try again.';
        }
      }, 8000);
    });

    onUnmounted(() => {
      clearTimeout(timeoutId);
    });

    return {
      isLoading,
      message: computed(() => store.state.linkingEmailResponse),
      error,
    };
  },
};
</script>

<style lang="scss" scoped>
.link-account {
    width: 100%;
    height: 100%;

    &__loading {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;

      svg {
        color: $color-dark-gray;
      }
    }

    &__copy {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
    }
}
</style>
