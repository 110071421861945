<template>
  <div class="autocomplete-input">
    <FCInput
      id="address"
      v-model="address"
      class="autocomplete-input__field"
      :class="{'autocomplete-input__field--results-displayed': areResultsDisplayed}"
      :value="address"
      title="Address Line 1*"
      name="address"
      type="text"
      :required="true"
      @onSubmit="onEnter"
      autocomplete="off" />
    <DropdownOptions
      v-show="areResultsDisplayed"
      :address-list="autoCompleteResults"
      :arrow-counter="arrowCounter"
      @onSelection="setDeliveryFields" />
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import FCInput from './FCInput.vue';
import { getFormattedAddressObj } from '../services/googleMaps/googleMapsHelpers';
import DropdownOptions from './DropdownOptions.vue';

export default {
  name: 'AutoCompleteInput',
  components: {
    FCInput,
    DropdownOptions,
  },
  props: {
    addressline1: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      address: this.addressline1,
      selectedAddressLine1: '',
      autoCompleteResults: [],
      areResultsDisplayed: false,
      arrowCounter: -1,
    };
  },
  watch: {
    address(newVal) {
      if (newVal?.length > 3 && newVal !== this.selectedAddressLine1) {
        this.debounce();
      }
    },
  },
  methods: {
    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.arrowCounter = -1;
        this.areResultsDisplayed = false;
      }
    },
    /*
      TODO fix so input field is keyboard accessible
    */
    onArrowDown() {
      if (this.arrowCounter < this.autoCompleteResults.length - 1) {
        this.arrowCounter += 1;
      }
    },
    onArrowUp() {
      if (this.arrowCounter > 0) {
        this.arrowCounter -= 1;
      }
    },
    onEnter() {
      const selectedResult = this.autoCompleteResults[this.arrowCounter];
      this.areResultsDisplayed = false;

      if (selectedResult) {
        this.setDeliveryFields(selectedResult.place_id);
        this.arrowCounter = -1;
      }
    },
    isItemSelected(index) {
      return index === this.arrowCounter;
    },
    async setDeliveryFields(placeId) {
      this.autoCompleteResults = [];

      const geoCodeResponse = await this.$googleMapsService.getGeoCodedAddress(placeId);
      const resultObj = getFormattedAddressObj(geoCodeResponse);

      if (Object.keys(resultObj).length !== 0) {
        this.selectedAddressLine1 = resultObj.line1;
        this.address = resultObj.line1;
        this.$emit('setFields', resultObj);
      }
    },
    debounce: debounce(
      async function handleInput() {
        const result = await this.$googleMapsService.getPlaces(this.address);

        if (Object.keys(result).length !== 0) {
          this.autoCompleteResults = result;
          this.areResultsDisplayed = true;
        } else {
          this.areResultsDisplayed = false;
        }
      },
      300,
    ),
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },
  unmounted() {
    document.removeEventListener('click', this.handleClickOutside);
  },
};
</script>

<style lang="scss" scoped>

  .autocomplete-input {
    width: 100%;
    position: relative;

    &__field {
      margin-bottom: 0.5rem;

      &--results-displayed {
        margin-bottom: 0;
      }
    }
  }
</style>
