<template>
  <Layout>
    <slot />
  </Layout>
</template>

<script>
import Layout from './Layout.vue';

export default {
  name: 'Callback',
  components: {
    Layout,
  },
};

</script>
