import { GrowthBook } from '@growthbook/growthbook';
import * as experimentKeys from '../constants/experiments';

const isDev = process.env.VUE_APP_IS_DEV === 'true';
const experiments = Object.values(experimentKeys);

export async function loadGrowthBook({ trackExperiment, dispatch }) {
  const userAnonId = window.analytics.user().anonymousId();

  const growthBook = new GrowthBook({
    apiHost: process.env.VUE_APP_GROWTHBOOK_URL,
    clientKey: process.env.VUE_APP_GROWTHBOOK_KEY,
    attributes: {
      id: userAnonId,
    },
    enableDevMode: isDev,
    trackingCallback: (experiment, result) => {
      trackExperiment({
        experimentId: experiment.key,
        variantId: result.key,
      });
    },
  });

  await growthBook.loadFeatures();

  experiments.forEach((exp) => {
    const variant = growthBook.evalFeature(exp);

    const payload = {
      variant: variant.value,
      id: exp,
    };

    dispatch('registerExperiment', payload);
  });
}
