import { createStore } from 'vuex';
import { apiClient } from '../services/apiClient';
import { asyncVuexAdapter } from './asyncVuexAdapter';
import { useAnalytics } from '../plugins/Segment';
import { hasPurchasedBagTop } from '../utils/helpers';
import { isAnnualCustomer, canCancelSubscription } from './getters';
import { validateAndLinkAccount } from './actions/validateAndLinkAccount';

const analytics = useAnalytics();

export default createStore({
  state: {
    account: {},
    initializing: false,
    requestInProgress: false,
    accountError: null,
    updateCardError: '',
    cardOnFileError: '',
    subscriptions: {},
    experiments: [],
    isUnlinked: false,
    linkingRequestEmail: '',
    linkingEmailSent: false,
    linkingEmailResponse: null,
    user: {},
  },
  mutations: {
    ...(asyncVuexAdapter.mutations),
    SET_UNLINKED_CUSTOMER(state, isUnlinked) {
      state.isUnlinked = isUnlinked;
    },
    GET_ACCOUNT_PENDING(state, isPending) {
      state.initializing = isPending;
    },
    GET_ACCOUNT_RESPONSE(state, payload) {
      state.account = payload;
    },
    GET_ACCOUNT_ERROR(state, payload) {
      state.accountError = payload;
    },
    CREATE_CARD_PENDING(state, isPending) {
      state.requestInProgress = isPending;
    },
    CREATE_CARD_RESPONSE(state, { paymentMethod, customer = null }) {
      state.account.paymentMethod = paymentMethod;
      state.account.customer.name = paymentMethod.billing_details.name;

      if (customer) {
        if (state.account.subscriptions.map((sub) => sub.status).includes('past_due')) {
          analytics.reactivateUser(customer.email);
        }

        state.account.subscriptions = customer.subscriptions.data;
      }
    },
    CREATE_CARD_ERROR(state, error) {
      state.updateCardError = error;
    },
    UPDATE_ADDRESS_PENDING(state, isPending) {
      state.requestInProgress = isPending;
    },
    SET_MEMBER_PREPAID(state, { description }) {
      analytics.trackEvent('Customer Dashboard | Upgrade Subscription', {
        email: state.account.customer.email,
        name: state.account.customer.name,
        stripeCustomerId: state.account.customer.id,
        stripeSubId: state.account.subscriptions[0].id,
        stripeLastestInvoiceId: state.account.subscriptions[0].latest_invoice,
        description,
      });

      state.account.prepaidCustomer = { isPrePaid: true, monthsRemaining: 12 };
    },
    REGISTER_EXPERIMENT(state, payload) {
      state.experiments.push(payload);
    },
    SET_CARD_ON_FILE_ERROR(state, payload) {
      state.cardOnFileError = payload;
    },
    SET_USER_LINKING_EMAIL(state, email) {
      state.linkingRequestEmail = email;
    },
    LINKING_EMAIL_SENT(state, isSent) {
      state.linkingEmailSent = isSent;
    },
    LINKING_EMAIL_RESPONSE(state, message) {
      state.linkingEmailResponse = message;
    },
    SET_USER(state, user) {
      state.user = user;
    },
  },
  actions: {
    ...(asyncVuexAdapter.actions),
    async getAccount({ commit }) {
      commit('GET_ACCOUNT_PENDING', true);

      try {
        const response = await apiClient.get('/account');
        if (response.data.email) {
          commit('SET_UNLINKED_CUSTOMER', true);
          commit('SET_USER_LINKING_EMAIL', response.data.email);
          return;
        }
        const invoices = await apiClient.get(`/invoices/${response.data.customer.id}`);

        // TODO refactor to make call to Stripe charges API https://fightcamp.atlassian.net/browse/FCA-2710?atlOrigin=eyJpIjoiNDY5YmVkNmZjNmNhNGFjY2JmYTRlOGU2MzY2N2RmNTAiLCJwIjoiaiJ9
        const draftInvoices = [];
        const openInvoices = [];

        invoices?.data?.data.forEach((invoice) => {
          if (invoice.status === 'draft') draftInvoices.push(invoice);
          if (invoice.status === 'open') openInvoices.push(invoice);
        });

        const sortedInvoices = invoices?.data?.data?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        const mostRecentInvoice = sortedInvoices[0];

        if ((draftInvoices.length > 1 || openInvoices.length >= 1) && mostRecentInvoice.status !== 'paid') {
          commit('SET_CARD_ON_FILE_ERROR', 'You may have an invalid payment method saved to your account. Please check if card saved is valid.');
        }

        commit('GET_ACCOUNT_RESPONSE', { ...response.data, invoices: invoices?.data?.data });
      } catch (err) {
        let errorMessage = 'Something went wrong.';

        if (err.response.status === 401) {
          const { type, data } = err.response.data;

          if (type === 'accountLogin') {
            errorMessage = {
              message: 'It seems you\'ve entered the subscription purchase email. To manage that subscription, please log out and login with your app login user and method.',
              hintsToDisplay: data,
            };
          }

          if (type === 'loginsToManage') {
            errorMessage = {
              message: 'It seems you\'ve entered the subscription purchase email. Please log out and try one of the following associated logins instead:',
              hintsToDisplay: data,
            };
          }

          if (type === 'alternativeLoginTypes') {
            const hints = data.map((hint) => ({
              email: null,
              loginType: hint,
            }
            ));
            errorMessage = {
              message: 'It seems you may have logged in with a different method than you use in the FightCamp app. Please log out and try one of the following instead:',
              hintsToDisplay: hints,
            };
          }

          commit('GET_ACCOUNT_ERROR', errorMessage);
          return;
        }

        errorMessage = { message: err?.response?.data?.message, hintsToDisplay: [] };
        commit('GET_ACCOUNT_ERROR', errorMessage);
      } finally {
        commit('GET_ACCOUNT_PENDING', false);
      }
    },
    async createCard({ commit }, { customerId, paymentMethodId }) {
      commit('CREATE_CARD_PENDING', true);

      try {
        const response = await apiClient.post('/createCard', {
          customerId,
          paymentMethodId,
        });
        commit('CREATE_CARD_RESPONSE', {
          paymentMethod: response.data.paymentMethod,
          customer: response.data.customer,
        });
      } catch (err) {
        commit('CREATE_CARD_ERROR', err.response.data.message);
      } finally {
        commit('CREATE_CARD_PENDING', false);
      }
    },
    async updateAddress({ commit, state }, { paymentMethodId, updatedDetails }) {
      commit('UPDATE_ADDRESS_PENDING', true);

      const body = {
        paymentMethodId,
        details: {
          address: {
            line1: updatedDetails.line1,
            line2: updatedDetails.line2,
            city: updatedDetails.city,
            state: updatedDetails.state,
            postal_code: updatedDetails.postal_code,
            country: updatedDetails.country,
          },
          name: updatedDetails.name,
        },
      };

      const billingAddressBody = {
        customerId: state.account.customer.id,
        address: body.details.address,
      };

      try {
        const response = await apiClient.post('/updateAddress', body);
        await apiClient.post('/updateBillingAddress', billingAddressBody);

        commit('CREATE_CARD_RESPONSE', { paymentMethod: response.data });
      } catch (err) {
        commit('CREATE_CARD_ERROR', err);
      } finally {
        commit('UPDATE_ADDRESS_PENDING', false);
      }
    },
    async generateLinkAccountUrl({ commit, state }, email) {
      await apiClient.post('/account/generate-link', { email, linkingRequestEmail: state.linkingRequestEmail });
      commit('LINKING_EMAIL_SENT', true);
    },
    validateAndLinkAccount,
    updateMemberPrepaid({ commit }, { description }) {
      commit('SET_MEMBER_PREPAID', { description });
    },
    registerExperiment({ commit }, { id, variant }) {
      commit('REGISTER_EXPERIMENT', { id, variant });
    },
  },
  getters: {
    ...(asyncVuexAdapter.getters),
    currentSubscriptions: (state, getters) => {
      const { subscriptions } = state?.account || {};

      return subscriptions?.map((subscription) => {
        const updatedSubscription = getters.getAsyncData(['subscriptions', subscription.id]);

        return updatedSubscription || subscription;
      })?.filter((sub) => sub.status !== 'canceled') ?? [];
    },
    getCustomerId: (state) => () => state.account && state.account.customer && state.account.customer.id,
    showBagAddon: (state) => (state.account?.nonSubscriptionOrders ? !hasPurchasedBagTop(state.account.nonSubscriptionOrders) : false),
    activationCode: ((state) => state.account.activationCode),
    isAnnualCustomer: (state, getters) => isAnnualCustomer(state, getters),
    canCancelSubscription: (state) => (subId) => canCancelSubscription(state, subId),
    hasOwnership: (state) => !!state.account.hasOwnership,
    hasUnpaidSubscription: (state) => state.account.subscriptions.some((sub) => sub.status === 'unpaid' || sub.status === 'past_due'),
    hasPurchasedConsole: (state) => state.account.hasPurchasedConsole,
    accountOwnerEmail: (state) => state?.account?.ownerEmail ?? null,
  },
});
