<template>
  <div class="select-container">
    <select
      :id="name"
      :required="required"
      :disabled="disabled"
      :value="modelValue"
      :autocomplete="autocomplete"
      :name="name"
      class="select"
      :class="{ 'normal': !error, 'error-select': error, 'disabled': disabled }"
      @change="$emit('update:modelValue', $event.target.value)">
      <option class="survey-option-none" />
      <option
        v-for="option in options"
        :key="option.key"
        class="option"
        :value="option.key">
        {{ option.name }}
      </option>
    </select>
    <label
      :for="name"
      class="floating-label"
      :class="{ 'error': error }">{{ label }}</label>
  </div>
</template>

<script>
export default {
  name: 'FCSelect',
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    autocomplete: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    error: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">

  .select-container {
    position: relative;
    width: 100%;
    margin-bottom: 1.5rem;
  }

  .select {
    line-height: 1.5em;
    appearance: none;
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: $input-radius;
    border: $border-light-outline;
    padding: 0.75rem 1.125rem 0.25rem 1.125rem;
    color: $color-black;
    font-size: 1rem;

    &:focus {
      border: 1px solid $color-black;
    }
  }

  .floating-label {
    position: absolute;
    pointer-events: none;
    left: 20px;
    top: 16px;
    transition: 0.2s ease all;
    color: $color-gray;
    font-size: 0.875rem;
  }

  select:disabled ~ .floating-label,
  select:focus ~ .floating-label,
  select:not(:focus):valid ~ .floating-label {
    top: 4px;
    bottom: 10px;
    font-size: 10px;
    opacity: 1;
  }

  .normal {
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
    background-image:
      linear-gradient(45deg, transparent 50%, gray 50%),
      linear-gradient(135deg, gray 50%, transparent 50%),
      linear-gradient(to right, #ccc, #ccc);
    background-position:
      calc(100% - 20px) calc(1em + 2px),
      calc(100% - 15px) calc(1em + 2px),
      calc(100% - 2.5em) 0.5em;
  }

  .error {
    border-color: $color-accent;
    color: $color-accent;
  }

  .error-select {
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
    border-color: $color-accent;
    color: $color-accent;
    background-image:
      linear-gradient(45deg, transparent 50%, $color-accent 50%),
      linear-gradient(135deg, $color-accent 50%, transparent 50%),
      linear-gradient(to right, $color-accent, $color-accent);
    background-position:
      calc(100% - 20px) calc(1em + 2px),
      calc(100% - 15px) calc(1em + 2px),
      calc(100% - 2.5em) 0.5em;
  }

  .survey-option-none {
    display: none;
  }

  .disabled {
    background-color: $color-light-background;
  }
</style>
