<template>
  <aside class="sidebar">
    <img class="sidebar__fc-logo" alt="FightCamp logo" src="../assets/logo.png">
  </aside>
</template>

<script>

export default {
  name: 'Sidebar',
};
</script>

<style lang="scss" scoped>
  .sidebar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $color-blue;
    min-width: 25%;

    @media #{$desktop} {
      top: 0;
      left: 0;
      position: fixed;
      min-height: 100%;
      z-index: 1;
      overflow-x: hidden;
    }

    &__fc-logo {
      padding: 2rem 0;
      max-width: 100px;

      @media #{$desktop} {
        top: 1rem;
        left: 1rem;
        position: fixed;
        padding: 0;
      }
    }
  }
</style>
