export function canCancelSubscription(state, subId) {
  const sub = state.account.subscriptions.find((s) => s.id === subId);
  const isInAbTest = sub.metadata.ABTest === '3 Months Subscription Minimum';
  if (!isInAbTest) return true;

  const { invoices } = state.account;
  const paid3Invoices = invoices.length > 3 && invoices.slice(0, 3).every((invoice) => invoice.amount_paid > 0 && invoice.paid);

  return isInAbTest && paid3Invoices;
}
