<template>
  <main class="dashboard-content">
    <div class="dashboard__not-owner" v-if="!hasOwnership && accountOwnerEmail">
      This user is not the primary user. Please login with <strong>{{ accountOwnerEmail }}</strong> to manage your subscription.
    </div>
    <h1 class="dashboard-title" >{{ title }}</h1>
    <Gen2Upgrade v-if="hasOwnership && !hasPurchasedConsole" />
    <KickboxingUpsell v-if=hasPurchasedConsole />
    <AnnualUpsell v-if="displayUpsell" />
    <ActivationCode v-if="hasOwnership" />
    <AccessoryAddOns v-if="showBagAddon" />
    <PaymentMethod />
    <BillingInformation />
    <Memberships />
    <Invoices v-if="hasOwnership" />
  </main>
</template>

<script>
import { onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import { useAnalytics } from '../plugins/Segment';
import BillingInformation from './BillingInformation.vue';
import Memberships from './Memberships.vue';
import PaymentMethod from './PaymentMethod.vue';
import ActivationCode from './ActivationCode.vue';
import Invoices from './Invoices.vue';
import Gen2Upgrade from './Gen2Upgrade.vue';
import AnnualUpsell from './AnnualUpsell.vue';
import AccessoryAddOns from './AccessoryAddOns.vue';
import KickboxingUpsell from './KickboxingUpsell.vue';

export default {
  name: 'Dashboard',
  components: {
    BillingInformation,
    Memberships,
    PaymentMethod,
    ActivationCode,
    Invoices,
    AnnualUpsell,
    AccessoryAddOns,
    Gen2Upgrade,
    KickboxingUpsell,
  },
  setup() {
    const analytics = useAnalytics();
    const store = useStore();

    const customer = computed(() => store.state.account.customer);

    onMounted(() => {
      analytics.emailIdentified(customer.value.email);
      analytics.pageViewed({ name: 'Dashboard' });
    });

    const hasPurchasedConsole = computed(() => store.getters.hasPurchasedConsole);
    const title = computed(() => (hasPurchasedConsole.value ? 'Manage Your FightCamp Membership' : 'Members Only Offers'));

    return {
      showBagAddon: computed(() => store.getters.showBagAddon),
      displayUpsell: computed(() => store?.getters?.hasOwnership && store?.getters?.currentSubscriptions?.length > 0),
      hasOwnership: computed(() => store.getters.hasOwnership),
      accountOwnerEmail: computed(() => store.getters.accountOwnerEmail),
      hasPurchasedConsole,
      title,
    };
  },
};
</script>

<style lang="scss" scoped>
.dashboard {
  &-content {
    text-align: start;
    margin: 2rem;

    @media #{$tablet} {
      width: 80%;
    }

    @media #{$desktop} {
      padding-left: 6rem;
      max-width: 944px;
    }
  }

  &-title {
    font-family: $font-stack-regular;
    text-align: center;
    text-transform: capitalize;
    font-size: 1.75rem;

    @media #{$tablet-up} {
      text-align: left;
    }
  }

  &-title--variant {
    font-family: $font-stack-regular;
    text-align: left;
    text-transform: capitalize;
    font-size: 1.75rem;
  }

  &__not-owner {
    padding: 2rem 0;
    font-size: 1rem;
    font-weight: bold;
    color: $color-red;
  }
}
</style>
