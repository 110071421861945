<script>
import { computed, ref } from 'vue';
import FCSelect from './FCSelect.vue';

const KICKBOXING_EXTENSION_DEV = new Map([
  ['fightcamp_kickboxing_pack_S', '41955557474393'],
  ['fightcamp_kickboxing_pack_L', '41955557507161'],
]);

const KICKBOXING_EXTENSION_PROD = new Map([
  ['fightcamp_kickboxing_pack_S', '45866332127468'],
  ['fightcamp_kickboxing_pack_L', '45866332160236'],
]);

function getUrl(uid) {
  const bundleId = process.env.VUE_APP_IS_DEV === 'true' ? KICKBOXING_EXTENSION_DEV.get(uid) : KICKBOXING_EXTENSION_PROD.get(uid);
  const url = `${process.env.VUE_APP_SHOP_BUNDLE_URL}${bundleId}`;

  return url;
}

export default {
  name: 'KickboxingUpsell',
  components: {
    FCSelect,
  },
  setup() {
    const kickboxingPack = {
      id: 'fightcamp_kickboxing_pack',
      copy: {
        title: 'Kickboxing Extension Pack',
        description: {
          text: "What You'll Get:",
          items: [
            '1 Pair of FightCamp Trackers',
            '1 Pair of Quick Wraps',
            '1 Pair of Ankle Wraps',
            'Free Shipping',
          ],
        },
        cta: 'Shop Now',
      },
      prices: {
        price: '$99',
        oldPrice: '$177',
      },
      selects: [
        {
          name: 'Wraps Sizes',
          key: 'gPair1',
          hint: 'Choose Wraps Sizes',
          options: [{
            key: 's',
            name: 'Small',
            htc: '',
            itemUrl: getUrl('fightcamp_kickboxing_pack_S'),
          },
          {
            key: 'l',
            name: 'Large',
            htc: '',
            itemUrl: getUrl('fightcamp_kickboxing_pack_L'),
          }],
        },
      ],
      img: {
        src: 'https://cdn.shopify.com/s/files/1/0031/3874/8505/files/R5__4719.jpg?v=1730413422',
        alt: 'FightCamp ankle wraps with trackers',
      },
    };

    const sizeSelected = ref('s');
    const selectsUrl = computed(() => (sizeSelected.value === 's' ? kickboxingPack.selects[0].options[0].itemUrl : kickboxingPack.selects[0].options[1].itemUrl));

    return {
      kickboxingPack,
      sizeSelected,
      selectsUrl,
    };
  },
};
</script>

<template>
    <div class="kickboxing-product__card" v-track-view="'KickboxingProduct_Card'">
    <div class="kickboxing-product__image-container">
      <div class="kickboxing-product__image-card">
        <img :src="kickboxingPack.img.src" :alt="kickboxingPack.img.alt" height="297" width="297" fetchpriority="high">
        <div class="kickboxing-product__image-content">
          <p class="kickboxing-product__price">{{ kickboxingPack.prices.price }} <span class="kickboxing-product__price--old">{{ kickboxingPack.prices.oldPrice }}</span></p>
          <p v-if="kickboxingPack.copy.priceDescription" class="kickboxing-product__small-text">{{ kickboxingPack.copy.priceDescription }}</p>
          <div class="kickboxing-product__size-selector">
            <FCSelect
              v-model="sizeSelected"
              name="size"
              hint="size"
              :label="kickboxingPack.selects[0].hint"
              :required="true"
              :options="kickboxingPack.selects[0].options" />
          </div>
          <a
            target="_blank"
            class="kickboxing-product__button button-normal button-layout"
            rel="noopener noreferrer"
            :href="selectsUrl"
            v-track-click="`Kickboxing_Pack_${sizeSelected}`">
            {{ kickboxingPack.copy.cta }}
          </a>
        </div>
      </div>
    </div>
    <div class="kickboxing-product__text">
      <h2 class="kickboxing-product__title">{{ kickboxingPack.copy.title }}</h2>
      <p class="kickboxing-product__description">{{ kickboxingPack.copy.description.text }}</p>
      <ul class="kickboxing-product__ul">
        <li v-for="item in kickboxingPack.copy.description.items" :key="item" class="kickboxing-product_li">{{ item }}</li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.kickboxing-product {
  &__card {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $color-white;
    color: $color-blue;
    margin-bottom: 5rem;

    @media #{$tablet-up} {
      padding: 1rem 2rem;
      flex-direction: row;
      box-shadow: 0 0.25rem 0.5rem rgba(15, 30, 60, 0.1);
      border-radius: $input-radius;
    }
  }

  &__image {
    &-container {
      width: 350px;
      padding: 2rem;
      border-radius: $input-radius;

      img {
        border-top-right-radius: $input-radius;
        border-top-left-radius: $input-radius;
        width: 100%;
      }

      @media #{$tablet-up} {
        background-position: center;
        background-size: cover;
        background-image: url('../assets/backgroundContainer.png');
      }
    }

    &-card {
      border-radius: $input-radius;
      background-color: $color-egg-shell;

      @media #{$tablet-up} {
        background-color: $color-white;
      }
    }

    &-content {
      padding: 1rem;
    }

    &-text {
      font-size: 0.875rem;
      margin: 0;
    }
  }

  &__text {
    @media #{$tablet-up} {
      display: flex;
      flex-direction: column;
      align-items: center;
      $left-size-width: 320px;
      padding: 0 .5rem;

      flex: 1 $left-size-width;

      & > * {
        max-width: $left-size-width;
      }
    }
  }

  &__title {
    width: 100%;
    text-align: left;
    font-family: $font-stack-heading;
    margin-bottom: 0.5rem;

    @media #{$tablet-up} {
      font-size: 2.25rem;
    }
  }

  &__description {
    width: 100%;
    margin: 0;
  }

  &__ul {
    margin-top: 0.25rem;
    padding: 0 0 0 1.5rem;
  }

  &__li {
    padding: 0 0 0 1rem;
  }

  &__size-selector {
    margin-top: 1rem;
  }

  &__error {
    font-size: 0.875rem;
    color: $color-red;
    margin: 0;
  }

  &__button {
    margin-top: 1rem;
    padding: 1rem;
    width: 100%;
  }

  &__price {
    font-family: $font-stack-heading;
    font-size: 1.375rem;
    margin: 0;
    text-transform: uppercase;
    color: $color-black;

    &--old {
      text-decoration: line-through;
    }
  }

  &__small-text {
    color: $color-blue;
    margin: 0;
    font-size: 0.85rem;
    word-break: normal;
    overflow-wrap: break-word;
    padding-top: 0.25rem;
  }

  &__copy-alert {
    font-family: $font-stack-bold;
    color: $color-blue;
  }

  &__hide-mobile {
    display: none;

    @media #{$tablet-up} {
      display: block;
    }
  }

  &__hide-tablet-up {
    display: block;

    @media #{$tablet-up} {
      display: none;
    }
  }

  &__url {
    word-break: break-all;
  }
}
</style>
