<template>
  <section v-track-view="'BagUpsell_DisplayExpVariant'">
    <SectionHeading text="Add Ons" />
    <AccessoryCard
      :title="freeStandingBag.title"
      :price="freeStandingBag.price"
      :description="freeStandingBag.description"
      :altText="freeStandingBag.altText"
      :image="freeStandingBag.image"
      :shopLink="freeStandingBag.shopLink" />
  </section>
</template>

<script>
import { computed } from 'vue';
import SectionHeading from './SectionHeading.vue';
import AccessoryCard from './AccessoryCard.vue';
import freeStandingBag from '../content/FreeStandingBag';

export default {
  name: 'AccessoryAddOns',
  components: {
    SectionHeading,
    AccessoryCard,
  },
  setup() {
    return {
      freeStandingBag: computed(() => freeStandingBag),
    };
  },
};
</script>
