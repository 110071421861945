<template>
    <div class="upgrade__mask">
      <div class="upgrade__wrapper">
        <div class="upgrade__container">
          <div class="upgrade__header">
            <h2 class="upgrade__title">{{ headingText }}</h2>
          </div>
          <div v-if="isEmailSent" class="upgrade__body">
            <p v-track-view="`${$options.name}_Modal_Confirmation`">Email sent! Please ask the recipient to forward you the email they received. Click the link in the email to join their subscription and activate your hardware.
            </p>
            <button
              class="button-layout button-outline"
              v-track-click="`${$options.name}_CloseBtn`"
              @click="$emit('closeModal')">
              Okay
            </button>
          </div>
          <div v-else class="upgrade__body">
            <p v-track-view="`${$options.name}_Modal`">
              To link an existing subscription to this user and activate your hardware, please enter the email address used at checkout.
            </p>
            <form>
              <FCInput
                v-model="email"
                class="upgrade__input"
                name="email"
                title="Email"
                :required="true"
                :disabled="isLoading"
              />
            </form>
            <div class="upgrade__confirm">
              <button
              type="button"
              class="button-big button-layout button-outline upgrade__button"
              v-track-click="`${$options.name}_ConfirmBtn`"
              @click="handleLinking">
                <font-awesome-icon icon="spinner" spin v-if="isLoading"/>
                <span v-else>
                  Send Email
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>

<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import FCInput from './FCInput.vue';

export default {
  name: 'LinkAccountEmailForm',
  components: {
    FCInput,
  },
  setup() {
    const email = ref('');
    const isLoading = ref(false);
    const store = useStore();
    const isEmailSent = computed(() => store.state.linkingEmailSent);
    const headingText = computed(() => (isEmailSent.value ? 'Email Sent!' : 'Link your subscription'));

    return {
      isEmailSent,
      headingText,
      email,
      handleLinking: async () => { await store.dispatch('generateLinkAccountUrl', email.value); },
      isLoading,
    };
  },
};
</script>

  <style lang="scss" scoped>
  .upgrade {
    &__mask {
      width: 100%;
    }

    &__title {
      margin: 0;
    }

    &__body {
      display: flex;
      flex-direction: column;
      width: 100%;

      p {
        margin: 0.5rem 0rem;
      }
    }

    &__error {
      color: $color-red;
    }

    &__button {
      background-color: $color-red;
      border-color: $color-red;
      color: $color-white;
      margin: 0.5rem 0 0;
    }
  }
  </style>
