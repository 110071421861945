<template>
  <div class="billing">
    <div class="billing-email">
      <p class="billing-title">Email</p>
      <p class="billing-details">{{ email }}</p>
    </div>
    <div class="billing-address">
      <p class="billing-title">Address</p>
      <div class="billing-details">
        <p class="billing-name">
          {{ name }}
        </p>
        <div v-if="card">
          <p class="billing-addressline1">
            {{ billingDetails.addressline1 }}
          </p>
          <p v-if="billingDetails.addressline2" class="billing-addressline2">
            {{ billingDetails.addressline2 }}
          </p>
          <p class="billing-addressline3">
            {{ billingDetails.city }},
            {{ billingDetails.state }}
            {{ billingDetails.postalCode }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { billingInfo } from '../utils/helpers';

export default {
  name: 'Billing',
  setup(props) {
    const billingDetails = computed(() => {
      if (props.card.billing_details && props.card.billing_details.address) {
        return billingInfo(props.card);
      }

      return '';
    });

    return { billingDetails };
  },
  props: {
    card: {
      type: Object,
      default: () => {},
    },
    email: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.billing {
  &-details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0;
  }

  &-email,
  &-address {
    display: flex;
    align-items: baseline;
    margin-bottom: 1rem;
    word-break: break-word;
  }

  &-email {
    color: $color-light-gray-3;
    margin-bottom: 2rem;
  }

  &-title {
    min-width: 100px;
    color: $color-light-gray-3;
    margin: 0;
  }

  &-name,
  &-addressline1,
  &-addressline2,
  &-addressline3 {
    text-transform: capitalize;
    margin: 0;
  }
}
</style>
