function billingInfo(card) {
  const details = card.billing_details.address;
  const billingObj = {
    addressline1: details.line1,
    addressline2: details.line2 ? details.line2 : '',
    city: details.city,
    state: details.state,
    postalCode: details.postal_code,
    country: details.country,
  };

  return billingObj;
}

function updateBillingWithNoPaymentOnFile(account) {
  const billingObj = {
    name: account.customer.name,
    address: {
      city: account.customer.shippingAddress.city,
      line1: account.customer.shippingAddress.line1,
      line2: account.customer.shippingAddress.line2,
      postal_code: account.customer.shippingAddress.postal_code,
      state: account.customer.shippingAddress.state,
      country: account.customer.shippingAddress.country,
    },
  };

  return billingObj;
}

function updateBillingWithPaymentOnFile(creditCard) {
  const billingObj = {
    name: creditCard.billing_details.name,
    address: {
      city: creditCard.billing_details.address.city,
      line1: creditCard.billing_details.address.line1,
      line2: creditCard.billing_details.address.line2,
      postal_code: creditCard.billing_details.address.postal_code,
      state: creditCard.billing_details.address.state,
      country: 'US',
    },
  };

  return billingObj;
}

function formatUnixToMonthYear(unixDate) {
  const date = new Date(unixDate * 1000);
  return `${date.toLocaleString('default', { month: 'long' })} ${date.getDate()}`;
}

function formatReadableDate(date) {
  const dateObj = new Date(date);
  const month = dateObj.toLocaleString('default', { month: 'short' });
  const day = dateObj.getDate();
  const year = dateObj.getFullYear();

  return `${month} ${day}, ${year}`;
}

function formatPrice(numberValue) {
  return `$${parseFloat(numberValue / 100.0).toFixed(2)}`;
}

function hasPurchasedBagTop(ordersArray) {
  const ordersWithBagTop = ordersArray.filter(
    (order) => order.line_items.some(
      (item) => item.sku === '102164',
    ),
  );

  return !!ordersWithBagTop.length;
}

export {
  billingInfo,
  updateBillingWithNoPaymentOnFile,
  updateBillingWithPaymentOnFile,
  formatUnixToMonthYear,
  formatReadableDate,
  formatPrice,
  hasPurchasedBagTop,
};
