import { computed } from 'vue';
import { useMembershipContext } from './useMembershipContext';

export function useSubscriptionActionableButtons(subId) {
  const unpaidStatuses = ['past_due', 'canceled', 'unpaid'];
  const { membershipState } = useMembershipContext(subId);
  const unpaidSub = unpaidStatuses.includes(membershipState.value.subscription.status);

  return computed(() => [
    {
      trackClickLabel: 'Membership_ReactivateBtn',
      actionType: 'reactivate',
      className: 'button-layout button-cta',
      text: 'Reactivate',
      filterHandler: () => membershipState.value.canceled && !membershipState.value.prepaid && !membershipState.value.prepaidOneYear,
    },
    {
      trackClickLabel: 'Membership_ResumeBtn',
      actionType: 'resume',
      className: 'button-layout button-cta',
      text: 'Resume',
      filterHandler: () => membershipState.value.paused && !membershipState.value.canceled && !membershipState.value.prepaid && !membershipState.value.prepaidOneYear,
    },
    {
      trackClickLabel: 'Membership_UpgradeBtn',
      actionType: 'upgrade',
      className: 'button-layout button-cta',
      text: 'Switch to Annual',
      filterHandler: () => !membershipState.value.paused && !membershipState.value.canceled && !membershipState.value.multipleSubs && !membershipState.value.prepaid && !unpaidSub && !membershipState.value.prepaidOneYear,
    },
    {
      trackClickLabel: 'Membership_PauseBtn_DisplayExpVariant',
      actionType: 'pause',
      className: 'button-layout button-secondary',
      text: 'Pause',
      filterHandler: () => !membershipState.value.paused && !membershipState.value.canceled && !membershipState.value.prepaid && !unpaidSub && !membershipState.value.prepaidOneYear,
    },
    {
      trackClickLabel: 'Membership_CancelBtn',
      actionType: 'cancel',
      className: 'button-layout button-secondary',
      text: 'Cancel',
      filterHandler: () => !membershipState.value.paused && !membershipState.value.canceled && !membershipState.value.prepaid && !unpaidSub && !membershipState.value.prepaidOneYear,
    },
  ].filter((buttonConfig) => buttonConfig.filterHandler()));
}
