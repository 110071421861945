<template>
  <button @click="$emit('buttonClicked')" class="icon-action-button" type="button">
    <font-awesome-icon data-testid="icon" :icon="icon" class="icon-action-button-icon" />
    {{label}}
  </button>
</template>

<script>
export default {
  name: 'IconAction',
  props: {
    label: {
      type: String,
      default: '',
      required: true,
    },
    icon: {
      type: String,
      default: '',
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-action {
  &-button {
    border: none;
    background-color: transparent;
    color: $color-blue;
    font-size: 15px;
    margin: 1rem 0;
    letter-spacing: .5px;
  }

  &-button-icon {
    margin-right: 6px;
  }
}
</style>
