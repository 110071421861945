import { createRouter, createWebHistory } from 'vue-router';
import { authGuard } from '@auth0/auth0-vue';

const Welcome = () => import('./components/Welcome.vue');
const LoggedIn = () => import('./components/LoggedIn.vue');
const Callback = () => import('./components/Callback.vue');

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Welcome,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: LoggedIn,
    beforeEnter: authGuard,
  },
  {
    path: '/callback', // used by auth0 to handle redirects after login
    name: 'Callback',
    component: Callback,
    beforeEnter: authGuard,
  },
  {
    path: '/link-account',
    name: 'LinkAccount',
    component: () => import('./components/LinkAccount.vue'),
    beforeEnter: authGuard,
  },
];

export const router = createRouter({
  history: createWebHistory(),
  routes,
});
