<template>
  <div>
    <div class="membership">
      <MembershipText :subId="subId" />
      <div v-show="displayButtons" class="membership__button-container">
        <Modal :hide-close-button="hideCloseButton">
          <template v-slot:trigger="slotProps">
            <button
              type="button"
              v-for="button in actionableButtons"
              v-track-click="button.trackClickLabel"
              :key="button.actionType"
              :class="button.className"
              @click="triggerModal(button.actionType, slotProps)">
              {{ button.text }}
            </button>
          </template>
          <template v-slot:modal-content="slotProps">
            <component
              v-if="selectedActionType"
              @closeModal="triggerModalClose(slotProps)"
              @openCancelModal="switchToCancel()"
              :is="selectedOverlayComponent"
              v-bind="selectedActionProps" />
          </template>
        </Modal>
      </div>
    </div>
    <div v-if="!isAnnualCustomer && !canCancelSubscription" class="membership__cancel-text">
      <p>3-month minimum membership required before you can cancel.</p>
      <p>Cancel anytime after {{ cancelDate }}.</p>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import Cancel from './Cancel.vue';
import Pause from './Pause.vue';
import Upgrade from './Upgrade.vue';
import ReviveSubscriptionOverlay from './ReviveSubscriptionOverlay.vue';
import Modal from './Modal.vue';
import { useSubscriptionActionableButtons } from '../composables/useSubscriptionActionableButtons';
import { SubscriptionActionType } from '../constants';
import MembershipText from './MembershipText.vue';
import { formatReadableDate } from '../utils/helpers';

export default {
  name: 'Membership',
  components: {
    ReviveSubscriptionOverlay,
    Modal,
    Cancel,
    Pause,
    Upgrade,
    MembershipText,
  },
  props: {
    subId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const selectedActionType = ref(null);
    const isCancelClicked = ref(false);
    const selectedActionProps = computed(() => ({
      subId: props.subId,
      actionType: selectedActionType.value,
      isCancelClicked: isCancelClicked.value,
    }));
    const hideCloseButton = computed(() => selectedActionType.value === SubscriptionActionType.RESUME
      || selectedActionType.value === SubscriptionActionType.REACTIVATE);
    const selectedOverlayComponent = computed(() => ((selectedActionType.value === SubscriptionActionType.REACTIVATE || selectedActionType.value === SubscriptionActionType.RESUME)
      ? 'ReviveSubscriptionOverlay'
      : selectedActionType.value));

    const store = useStore();
    const sub = computed(() => store.state.account.subscriptions.find((s) => s.id === props.subId));
    const cancelDate = computed(() => {
      const date = new Date(sub.value.trial_end * 1000);
      date.setMonth(date.getMonth() + 3);
      return formatReadableDate(date);
    });
    const canCancelSubscription = computed(() => store.getters.canCancelSubscription(props.subId));
    const actionableButtons = useSubscriptionActionableButtons(props.subId);
    const hasOwnership = computed(() => store.getters.hasOwnership);
    const displayButtons = computed(() => !!actionableButtons.value.length && canCancelSubscription.value && hasOwnership.value);

    function triggerModal(modalType, modalProps) {
      if (modalType === SubscriptionActionType.CANCEL) {
        selectedActionType.value = SubscriptionActionType.PAUSE;
        isCancelClicked.value = true;
      } else {
        selectedActionType.value = modalType;
        isCancelClicked.value = false;
      }

      modalProps.showModal();
    }

    function triggerModalClose(modalProps) {
      selectedActionType.value = null;
      modalProps.hideModal();
    }

    function switchToCancel() {
      selectedActionType.value = SubscriptionActionType.CANCEL;
    }

    return {
      actionableButtons,
      triggerModal,
      triggerModalClose,
      selectedActionType,
      selectedOverlayComponent,
      selectedActionProps,
      hideCloseButton,
      switchToCancel,
      displayButtons,
      canCancelSubscription,
      isAnnualCustomer: computed(() => store.getters.isAnnualCustomer),
      cancelDate,
    };
  },
};
</script>

<style lang="scss" scoped>
.membership {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 0;

  @media #{$tablet-up} {
    flex-direction: row;
    width: 100%;
  }

  &__button-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @media #{$tablet-up} {
      flex-direction: row;
      margin-top: 0.3125rem;
      margin-left: auto;
      justify-content: flex-end;
    }

    .button-layout {
      flex-basis: 49%;
      margin: 0.5rem 0;

      @media #{$tablet-up} {
        flex-basis: auto;
        align-self: baseline;
        margin: 0rem 0rem 0rem 0.5rem;
      }
    }

    .button-hide {
      display: none;
    }
  }

  &__cancel-text {
    p {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}
</style>
