<template>
  <div class="cancel-mask">
    <div class="cancel-wrapper">
      <div class="cancel-container">
        <div class="cancel-header">
          <p class="cancel-title">Before you go...</p>
        </div>
        <div class="cancel-body">
          <div id="smcx-sdk"></div>
          <div class="cancel-confirm-cancel">
            <button
            class="button-big button-layout"
            :class="delayEnable ? 'button-disabled' : 'button-outline'"
            :disabled="delayEnable"
            v-track-click="'Cancel_ConfirmBtn'"
            @click="handleCancel">
            <font-awesome-icon icon="spinner" spin v-if="isLoading"/>
            <span v-else>
              Cancel Membership
            </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue';
import { useMembershipContext } from '../composables/useMembershipContext';

export default {
  name: 'Cancel',
  setup(props, context) {
    const {
      isLoading,
      mutate,
    } = useMembershipContext(props.subId).cancel;
    const delayEnable = ref(true);

    onMounted(() => {
      const surveyScript = document.createElement('script');
      surveyScript.src = 'https://widget.surveymonkey.com/collect/website/js/tRaiETqnLgj758hTBazgd1kjY81gOW4deU5rYd4ZHWc7G5pazP3DodcB_2FbD8iblf.js';
      document.body.appendChild(surveyScript);

      setTimeout(() => {
        delayEnable.value = false;
      }, 5000);
    });

    async function handleCancel() {
      if (!isLoading.value) {
        await mutate();
        context.emit('closeModal');
      }
    }

    return {
      delayEnable,
      isLoading,
      handleCancel,
    };
  },
  props: {
    subId: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>

.cancel {
  &-container {
    transition: all 0.3s ease;
    font-family: Helvetica, Arial, sans-serif;
    min-height: 100%;
  }

  &-title {
    font-size: 1.25rem;
    margin: 0;
  }

  &-body {
    margin: 1.25rem 0rem;
    height: 100%;
    padding-left: 1rem;
  }

  &-confirm-cancel {
    margin-top: 3rem;
  }
}

.cancel-enter {
  opacity: 0;
}

.cancel-leave-active {
  opacity: 0;
}

.cancel-enter .cancel-container,
.cancel-leave-active .cancel-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

::v-deep(.smcx-widget){
  min-height: 65vh;
  max-width: 100%;
  border: none;
}

::v-deep(.smcx-widget.smcx-embed > .smcx-iframe-container) {
  width: 100%;
  max-width: 100%;
  height: 100%;
}
</style>
