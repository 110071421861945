import { SegmentWrapper } from './segment';
import { TrackView } from './track-view-element';
import { TrackClick } from './track-click';

export const FCAnalytics = {
  install(app, options) {
    const analytics = window.analytics = window.analytics || [];

    if (!analytics.initialize) {
      if (analytics.invoked) {
        if (window.console && console.error) {
          console.error('Segment snippet included twice.');
        }
        return;
      }

      analytics.invoked = true;
      analytics.methods = ['trackSubmit', 'trackClick', 'trackLink', 'trackForm', 'pageview', 'identify', 'reset', 'group', 'track', 'ready', 'alias', 'debug', 'page', 'once', 'off', 'on', 'addSourceMiddleware', 'addIntegrationMiddleware', 'setAnonymousId', 'addDestinationMiddleware'];

      analytics.factory = function (method) {
        return function () {
          const args = Array.prototype.slice.call(arguments);
          console.log(`[Segment Analytics Debug]: ${method} method called with ${args.length} args`);
          args.unshift(method);
          analytics.push(args);
          return analytics;
        };
      };

      for (let e = 0; e < analytics.methods.length; e++) {
        const key = analytics.methods[e]; analytics[key] = analytics.factory(key);
      }

      analytics.load = function (loadOptions) {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src = `https://cdn.segment.com/analytics.js/v1/${options?.writeKey}/analytics.min.js`;

        const first = document.getElementsByTagName('script')[0];
        first.parentNode?.insertBefore(script, first);
        analytics._loadOptions = loadOptions;
      };

      analytics._writeKey = options?.writeKey;
      analytics.SNIPPET_VERSION = '4.13.2';
      analytics.load(options?.writeKey);
    }

    app.directive('track-click', TrackClick);
    app.directive('track-view', TrackView);

    app.config.globalProperties.$analytics = SegmentWrapper;
  },
};

export function useAnalytics() {
  return SegmentWrapper;
}
