<template>
    <div class="unlinked">
    <p class="unlinked__text"> You have no subscription linked to your console. Please select subscription to purchase</p>
    <div class="unlinked__button-container">
        <a
        v-for="button in actionableButtons"
        :href="button.url"
        target="_blank"
        rel="noopener noreferrer"
        v-track-click="button.trackClickLabel"
        class="unlinked__button--sub button-layout button-cta"
        :key="button.actionType">
            {{ button.text }}
        </a>
    </div>
    <p>OR is your subscription linked to another email?</p>
    <LinkSubscriptionCard />
      <p class="unlinked__text"> Please <a target="_blank" rel="noopener noreferrer" href="https://support.joinfightcamp.com/hc/en-us">contact</a>
    customer support if you have any questions. </p>
    <br>
    </div>
  </template>

<script>
import LinkSubscriptionCard from './LinkSubscriptionCard.vue';

export default {
  name: 'UnLinked',
  components: {
    LinkSubscriptionCard,
  },
  setup() {
    const shopUrl = process.env.VUE_APP_SHOP_BUNDLE_URL;
    const isDev = process.env.VUE_APP_IS_DEV === 'true';

    const monthlyShopifyId = isDev ? '41700120395865' : '45583703408876';
    const yearlyShopifyId = isDev ? '41700108370009' : '45583739486444';

    return {
      actionableButtons: [
        {
          url: `${shopUrl}${monthlyShopifyId}`,
          actionType: 'purchaseNonEcommMonthlySub',
          text: 'Purchase Monthly',
          trackClickLabel: 'purchaseNonEcommMonthlySub',
        },
        {
          url: `${shopUrl}${yearlyShopifyId}`,
          actionType: 'purchaseNonEcommYearlySub',
          text: 'Purchase Yearly',
          trackClickLabel: 'purchaseNonEcommYearlySub',
        },
      ],
    };
  },
};
</script>

  <style lang="scss" scoped>
  .unlinked {
    text-align: center;
    padding: 1rem;

    &__text {
      font-size: 20px;
    }

    &__button-container {
      display: flex;
      flex-direction: column;
      max-width: 370px;
      margin: 0 auto;
    }

    &__button {
      margin: 0 auto;

      &--sub {
        margin: 1rem 4rem;
      }
    }
  }
  </style>
