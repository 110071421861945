<template>
  <h3 v-track-view="`${text}_Section`" class="section__heading">{{text}}</h3>
</template>

<script>
export default {
  name: 'SectionHeading',
  props: {
    text: {
      type: String,
      default: '',
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
  .section__heading {
    color: $color-blue;
    box-shadow: inset 0 -1px $color-light-gray;
    margin-bottom: 1rem;
    padding-bottom: .8rem;
    text-transform: uppercase;
  }
</style>
