import states from '../../constants/iso_state_code.json';

export function findAddressData(data, detail) {
  const dataFound = data.find((item) => item.types.includes(detail));

  if (!dataFound) {
    return '';
  }

  if (detail === 'country') {
    return dataFound.short_name || '';
  }

  return dataFound.long_name || '';
}

export function getAbbrStateObj(data) {
  const stateFullName = findAddressData(data, 'administrative_area_level_1');
  const abbrStateObj = states.find((i) => i.name === stateFullName && i.name !== '');

  return abbrStateObj || {};
}

export function getFormattedAddressObj(addressResponse) {
  if (isResponseError(addressResponse)) {
    return {};
  }

  const data = addressResponse[0].address_components;
  const formattedAddressObj = {
    line1: `${findAddressData(data, 'street_number')} ${findAddressData(data, 'route')}`,
    city: findAddressData(data, 'locality'),
    state: getAbbrStateObj(data).key || '',
    postal_code: findAddressData(data, 'postal_code'),
    country: findAddressData(data, 'country'),
  };

  return formattedAddressObj;
}

function isResponseError(response) {
  return !response[0];
}
