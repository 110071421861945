<template>
  <ul
    role="menu"
    class="dropdown-options">
    <li
      v-for="(address, index) in addressList"
      :key="index"
      :data-testid="`dropdown-options-li-${index}`"
      role="menuitem"
      class="dropdown-options__result fc-text-blue fc-text"
      :class="{ 'fc-bg-light-gray-1': isItemSelected(index) }"
      @click="$emit('onSelection', address.place_id)">
      {{ address.description }}
    </li>
  </ul>
</template>

<script>
export default {
  name: 'DropdownOptions',
  props: {
    addressList: {
      type: Array,
      required: true,
    },
    arrowCounter: {
      type: Number,
      required: true,
    },
  },
  methods: {
    isItemSelected(index) {
      return index === this.arrowCounter;
    },
  },
};
</script>

<style lang="scss" scoped>
  .dropdown-options {
    padding: 0;
    margin: 0;
    border: 1px solid $color-egg-shell;
    height: 100%;
    position: absolute;
    z-index: 5;
    width: 100%;

    &__result {
      list-style: none;
      text-align: left;
      margin: 0;
      cursor: pointer;
      padding: 0.75rem 18px 0.25rem;
      background-color: $color-egg-shell;

      &:last-child {
        padding: 0.75rem 18px;
      }

      &:hover {
        background-color: $color-light-gray;
      }
    }

    &__options {
      background-color: $color-egg-shell;
    }
  }
</style>
