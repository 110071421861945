<template>
  <div class="accessory-card">
    <div class="accessory-card__container">
      <img class="accessory-card__image" :src="image" :alt="altText" />
      <div class="accessory-card__container__info">
        <div class="accessory-card__details">
          <h1 class="accessory-card__title">{{ title }}</h1>
          <h3 class="accessory-card__price">{{ formattedPrice }}</h3>
          <p class="accessory-card__description">{{ description }}</p>
        </div>
      </div>
      <a class="accessory-card__button" v-track-click="`AddToCart_${title}`" :href="shopLink" target="_blank" rel="noopener noreferrer">Add to Cart</a>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { formatPrice } from '../utils/helpers';

export default {
  name: 'AccessoryCard',
  props: {
    title: {
      type: String,
      required: true,
    },
    price: {
      type: Number,
      required: true,
    },
    description: {
      type: String,
      required: false,
    },
    shopLink: {
      type: String,
      required: true,
    },
    image: {
      type: String,
      required: true,
    },
    altText: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    return {
      formattedPrice: computed(() => formatPrice(props.price)),
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/app2.scss";

.accessory-card {
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  column-gap: 1.25rem;
  row-gap: 2rem;

  &__container {
    background-color: $color-egg-shell;
    border: none;
    padding: 2rem;
    width: 100%;
    height: 100%;
    padding: 0;
    display: flex;
    flex-flow: column;
    grid-column: span 6;

    @media #{$tablet-up} {
      grid-column: span 3;
    }

    &__info {
      padding: 0 1rem;
    }
  }

  &__image {
    overflow: hidden;
    width: 100%;
    object-position: 50% 50%;
    object-fit: cover;
  }

  &__details {
    text-align: center;

    @media #{$tablet-up} {
      text-align: left;
    }
  }

  &__title {
    color: $color-red;
    font-size: 2.25rem;
    text-align: center;
    width: 80%;
    margin: 0 auto;
    padding-top: 1rem;

    @media #{$tablet-up} {
      font-size:  3rem;
      text-align: left;
      width: 100%;
    }
  }

  &__price {
    color: $color-blue;
    margin: 0;
    padding: 0.5rem 0;

    @media #{$tablet-up} {
      text-align: left;
      padding: 0.5rem 0 1rem 0;
    }
  }

  &__description {
    font-family: $font-stack-light;
    color: $color-black;
    text-align: center;
    margin: 0;
    padding-bottom: 1rem;
    font-size:  0.85rem;

    @media #{$tablet-up} {
      text-align: left;
      font-size:  1rem;
    }
  }

  &__button {
    border: 1px solid $color-blue;
    text-transform: uppercase;
    color: $color-blue;
    background-color: transparent;
    font-size: 0.9rem;
    border-radius: 12px;
    font-family: $font-stack-bold;
    padding: 18px 30px;
    cursor: pointer;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    text-decoration: none;
    display: flex;
    width: auto;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    line-height: 100%;
    margin: 1rem;

    @media #{$tablet-up} {
      padding: 0.8rem;
      font-size: 0.8rem;
    }

    &:hover {
      box-shadow: $button-hover-shadow;
    }
  }
}
</style>
