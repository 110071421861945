<template>
  <footer class="footer">
    <div class="footer-legal-column">
      <div class="footer-legal-column-content">
        <p class="footer-copyright">
          Copyright © FightCamp 2017-{{ new Date().getFullYear() }},
          Hykso, Inc. All rights reserved.
        </p>
        <div class="footer-legal-links">
          <a
            class="footer-legal-link"
            target="_blank" rel="noopener noreferrer"
            href="https:joinfightcamp.com/legal-terms/">
            Legal Terms
          </a>
          <a
            class="footer-legal-link"
            target="_blank" rel="noopener noreferrer"
            href="https:joinfightcamp.com/privacy-policy/">
            Privacy Policy
          </a>
            <a
            class="footer-legal-link"
            target="_blank" rel="noopener noreferrer"
            href="https://support.joinfightcamp.com/hc/en-us">
            Help Center
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
};
</script>

<style scoped lang="scss">
.footer {
  background-color: $color-light-gray-1;
  display: flex;
  justify-content: center;
  padding: 2rem .5rem;
  flex-wrap: wrap;
  text-align: center;
  height: 10rem;
  width: 100%;

  @media #{$desktop} {
    width: 75%;
    margin-left: 25%;
  }

  &-legal-column-content {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-legal-column {
    margin-bottom: 1rem;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &-copyright {
    font-size: 12px;
    margin: 0;
  }

  &-legal-links {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  &-legal-link {
    text-decoration: underline;
    font-size: 12px;
    color: $color-black;
    margin-right: 1rem;
    padding: 15px 0;
  }
}
</style>
