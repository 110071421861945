<template>
  <div class="not-authorized">
    <p class="not-authorized-text">{{ message }} </p>
    <div v-if="hintsToDisplay" class="not-authorized__hints">
      <ul>
        <li v-for="hint in hintsToDisplay" :key="hint">
          <span v-if="hint.email">{{ hint.email }} with {{ hint.loginType }}</span>
          <span v-if="!hint.email">{{ hint.loginType }}</span>
          <span class="not-authorized__icon" v-if="hint.icon">
            <font-awesome-icon :icon="hint.icon" />
          </span>
        </li>
      </ul>
    </div>
    <p class="not-authorized-text"> Please <a target="_blank" rel="noopener noreferrer" href="https://support.joinfightcamp.com/hc/en-us">contact</a>
  customer support if you have any questions. </p>
  </div>
</template>

<script>
import { computed } from 'vue';

export default {
  name: 'NotAuthorized',
  props: {
    error: {
      type: Object,
      default: () => ({
        message: 'You are not authorized to view this page.',
        hintsToDisplay: [],
      }),
    },
  },
  setup(props) {
    const message = computed(() => {
      if (props?.error?.message) {
        return props.error.message;
      }
      return props.error;
    });

    const hintsToDisplay = computed(() => {
      if (props?.error?.hintsToDisplay.length > 0) {
        return props.error.hintsToDisplay.map((hint) => ({
          email: hint.email,
          loginType: hint.loginType === 'Password' ? 'Email' : hint.loginType,
          icon: getIcon(hint.loginType),
        }));
      }
      return null;
    });

    const getIcon = (loginType) => {
      const iconMap = {
        Password: ['fa', 'envelope'],
        Facebook: ['fab', 'facebook'],
        Apple: ['fab', 'apple'],
      };

      return iconMap[loginType] || null;
    };

    return {
      message,
      hintsToDisplay,
      getIcon,
    };
  },
};
</script>

<style lang="scss" scoped>
.not-authorized {
  text-align: center;
  padding: 1rem;

  &-text {
    font-size: 20px;
  }
}

.not-authorized__hints {
  display: flex;
  justify-content: center;
  max-width: 80%;
  margin: 0 auto;
}

.not-authorized__icon {
  margin-left: 0.5rem;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  font-size: 20px;
}

</style>
