<template>
  <div class="dashboard">
    <div>
      <button @click="logout" class="layout__logout" type="button" v-track-click="'Dashboard_LogoutBtn'">
        Logout
      </button>
    </div>
    <Sidebar />
    <div class="layout__main-container">
      <div class="layout__main">
        <slot />
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
import { useAuth0 } from '@auth0/auth0-vue';
import Footer from './Footer.vue';
import Sidebar from './Sidebar.vue';

export default {
  name: 'Layout',
  components: {
    Footer,
    Sidebar,
  },
  setup() {
    const { logout } = useAuth0();

    return {
      logout,
    };
  },
};
</script>

<style lang="scss" scoped>

.layout {
  &__logout {
    position: absolute;
    right: 15px;
    top: 15px;
    color: $color-white;
    background: transparent;
    border: none;
    font-size: 16px;

    @media #{$desktop} {
      color: $color-black;
    }
  }

  &__main-container {
    display: flex;
    flex-direction: column;
  }

  &__main {
    width: 100%;
    padding-bottom: 5rem;
    flex: 1 0;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;

    @media #{$desktop} {
      margin-left: 25%;
      width: 75%;
      justify-content: flex-start;
    }
  }
}
</style>
