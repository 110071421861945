<template>
  <div class="upgrade__mask">
    <div class="upgrade__wrapper">
      <div class="upgrade__container">
        <div class="upgrade__header">
          <h2 class="upgrade__title">{{ headingText }}</h2>
        </div>
        <div v-if="upgradeConfirmed" class="upgrade__body">
          <p v-track-view="`${$options.name}_Modal_Confirmation`">Your subscription has been updated.
          </p>
          <button
            class="button-layout button-outline"
            v-track-click="`${$options.name}_CloseBtn`"
            @click="$emit('closeModal')">
            Okay
          </button>
        </div>
        <div v-else class="upgrade__body">
          <p class="upgrade__error" v-if="upgradeError" v-html="upgradeError" />
          <p v-track-view="`${$options.name}_Modal`">
            By confirming, you will be charged $421.20 to the authorized card on file, lowering your membership from $39.00/month to
            $35.10/month (plus applicable taxes). For more details, read  <a target="_blank" rel="noopener noreferrer" href="https://support.joinfightcamp.com/hc/en-us/articles/8334667190939-Get-10-off-your-FightCamp-membership-when-you-switch-to-a-yearly-payment-plan">this article</a>.
          </p>
          <div class="upgrade__confirm">
            <button
            class="button-big button-layout button-outline upgrade__button"
            v-track-click="`${$options.name}_ConfirmBtn`"
            @click="handleUpgrade">
              <font-awesome-icon icon="spinner" spin v-if="isLoading"/>
              <span v-else>
                Confirm
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { useMembershipContext } from '../composables/useMembershipContext';

export default {
  name: 'Upgrade',
  props: {
    subId: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const {
      isLoading,
      error,
      mutate,
    } = useMembershipContext(props.subId).upgrade;
    const isUpgraded = ref(false);
    const upgradeConfirmed = computed(() => isUpgraded.value && !error.value && !isLoading.value);

    async function handleUpgrade() {
      if (!isLoading.value) {
        await mutate();
        isUpgraded.value = true;
      }
    }

    const headingText = computed(() => (upgradeConfirmed.value ? 'Upgrade Confirmed' : 'Confirm to Save 10%'));

    return {
      isUpgraded,
      isLoading,
      handleUpgrade,
      upgradeError: error,
      upgradeConfirmed,
      headingText,
    };
  },
};
</script>

<style lang="scss" scoped>
.upgrade {
  &__mask {
    width: 100%;
  }

  &__title {
    margin: 0;
  }

  &__body {
    display: flex;
    flex-direction: column;
    width: 100%;

    p {
      margin: 0.5rem 0rem;
    }
  }

  &__error {
    color: $color-red;
  }

  &__button {
    background-color: $color-red;
    border-color: $color-red;
    color: $color-white;
    margin: 0.5rem 0 0;
  }
}
</style>
