<template>
  <div class="subscription-action-overlay">
    <p v-html="modalCopy" />
    <div class="resume__loading" v-if="isLoading">
      <font-awesome-icon icon="spinner" size="2x" spin />
    </div>
    <button class="button-layout button-outline" v-if="!isLoading" @click="$emit('closeModal')">
      Close
    </button>
  </div>
</template>

<script>
import { computed, onMounted, ref } from 'vue';
import { SubscriptionActionType } from '../constants';
import { useMembershipContext } from '../composables/useMembershipContext';
import { useAccount } from '../composables/useAccount';

export default {
  name: 'ReviveSubscriptionOverlay', // aka reactivate modal
  props: {
    subId: {
      type: String,
      default: '',
    },
    actionType: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const {
      isLoading,
      error,
      mutate,
    } = useMembershipContext(props.subId)[props.actionType];
    const overlayCopies = {
      [SubscriptionActionType.CREATE]: {
        loading: 'Please wait while we\'re restarting your subscription...',
        loaded: 'Thank you for restarting your subscription to FightCamp!',
        error: 'Sorry there has been an issue while trying to restart your subscription. Please <a target="_blank" rel="noopener noreferrer" href="https://support.joinfightcamp.com">contact</a> customer support.',
      },
      [SubscriptionActionType.RESUME]: {
        loading: 'Please wait while we\'re resuming your subscription...',
        loaded: 'Thank you for resuming your subscription to FightCamp!',
        error: 'Sorry there has been an issue while trying to resume your subscription. Please <a target="_blank" rel="noopener noreferrer" href="https://support.joinfightcamp.com">contact</a> customer support.',
      },
      [SubscriptionActionType.REACTIVATE]: {
        loading: 'Please wait while we\'re reactivating your subscription...',
        loaded: 'Thank you for reactivating your subscription to FightCamp!',
        error: 'Sorry there has been an error while trying to reactivate your subscription. Please <a target="_blank" rel="noopener noreferrer" href="https://support.joinfightcamp.com">contact</a> customer support.',
      },
    };

    const validateError = ref(null);

    const modalCopy = computed(() => {
      const selectedCopy = overlayCopies[props.actionType];

      if (selectedCopy && isLoading.value) {
        return selectedCopy.loading;
      }

      if (validateError.value) {
        return validateError.value;
      }

      if (selectedCopy && error.value && !isLoading.value) {
        return selectedCopy.error;
      }

      return selectedCopy && selectedCopy.loaded;
    });

    const { card } = useAccount();
    onMounted(() => {
      if (card.value) {
        mutate();
      } else {
        validateError.value = 'Sorry we are unable to reactivate your membership without a card on file. Please provide a credit card.';
      }
    });

    return {
      isLoading,
      modalCopy,
    };
  },
};
</script>

<style lang="scss">
.subscription-action-overlay {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}
</style>
