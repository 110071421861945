<template>
  <section class="invoices">
    <SectionHeading text="Invoice History" />
    <p class="invoices-text">
      Invoices are available for only the previous 12 months. Please <a target="_blank" rel="noopener noreferrer" href="https://support.joinfightcamp.com">contact</a> customer support for older invoices.
    </p>
    <div class="invoices__list">
      <p v-if="noInvoicesFound">No invoices found.</p>
      <div v-else>
        <div v-for="invoice in filteredInvoices" :key="invoice.id" class="invoices__item">
          <div class="invoices__item_date">
            {{ formatReadableDate(invoice.created * 1000) }}
          </div>
          <div class="invoices__item_amount">
            {{ formatInvoiceTotal(invoice.total) }}
          </div>
          <div class="invoices__item_status">
            {{ invoice.status }}
          </div>
          <div class="invoices__item_download">
            <a :href="invoice.hosted_invoice_url" target="_blank" rel="noopener noreferrer">
              Download
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import SectionHeading from './SectionHeading.vue';
import { formatReadableDate } from '../utils/helpers';

export default {
  name: 'Invoices',
  components: {
    SectionHeading,
  },
  setup() {
    const store = useStore();
    const invoices = computed(() => store.state.account.invoices);

    // filter out customer's unpaid invoices marked as status 'draft'
    const filteredInvoices = computed(() => invoices.value?.filter((invoice) => invoice.status !== 'draft')) ?? [];
    const noInvoicesFound = computed(() => filteredInvoices.value?.length === 0);

    function formatInvoiceTotal(total) {
      let price = total / 100;

      // check if price is an integer or a float
      if (price !== Math.floor(price)) {
        price = price.toFixed(2);
      }

      return `$${price}`;
    }

    return {
      filteredInvoices,
      noInvoicesFound,
      formatReadableDate,
      formatInvoiceTotal,
    };
  },
};
</script>

<style lang="scss" scoped>
.invoices {
  &-text {
    margin-top: 0;
    font-size: .875rem;
    color: $color-light-gray-3;
  }

  &__list {
    display: flex;
    flex-direction: column;
    width: 80%;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    text-align: left;
    padding: 0.75rem 0;
  }

  &__item_status {
    text-transform: capitalize;
  }
}
</style>
